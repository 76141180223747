@import "./variables";

.SignIn,
.modal_signin {
    margin-top: 32px;
    > a {
        display: block;
        margin: auto;
        width: fit-content;
    }
    .logo {
        display: block;
        width: 160px;
        margin-left: auto;
        margin-right: auto;
    }
    .header {
        text-align: center;
    }
    a:has(.logo) + p {
        position: relative;
        display: inline-block;
        margin-top: 48px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.6;
        strong {
            background: $color_contents_grad;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        &::before,
        &::after {
            position: absolute;
            top: 50%;
            content: "";
            display: block;
            width: 77px;
            height: 2px;
            border-radius: 2px;
            background: $color_contents_grad;
        }
        &::before {
            transform: translateY(-50%) rotate(70deg);
            left: -52px;
        }
        &::after {
            transform: translateY(-50%) rotate(110deg);
            right: -52px;
        }

    }
    .container {
        width: calc(100vw - 32px);
        margin: 32px auto 0;
        padding: 32px;
        border-radius: 10px;
        background-color: $color_background_primary;
    
        @media screen and (min-width: 600px) {
            width: 400px;
        }
    }
    .firebaseui-input,
    .firebaseui-input-invalid {
        width: 100%;
        height: 48px;
        padding: 8px;
        border: 1px solid $color_border;
        border-radius: 10px;
        outline: none;
    }
    .firebaseui-input {
        &:focus {
            border: 2px solid $color_border_accent;
        }
        &:-webkit-autofill {
            box-shadow: 0 0 0 1000px white inset;
        }
    }
    .firebaseui-input-invalid {
        border: 2px solid $color_border_negative;
        background-color: $color_background_negative_pale;
    }
    .firebaseui-label {
        display: block;
        font-size: 12px;
        + input {
            margin-top: 8px;
        }
    }
    .firebaseui-id-submit,
    button.firebaseui-id-secondary-link {
        width: 100%;
        height: 48px;
        border: none;
        border-radius: 24px;
        cursor: pointer;
        font-weight: 500;
    }
    .firebaseui-id-submit {
        background: $color_background_grad;
        color: $color_contents_inverse;
    }
    button.firebaseui-id-secondary-link {
        order: 2;
        position: relative;
        margin-top: 16px;
        background: none;
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid transparent;
            border-radius: 24px;
            background: $color_background_grad border-box border-box;
            mask: linear-gradient($color_background_primary 0 0) padding-box, linear-gradient($color_background_primary 0 0) border-box;
            mask-composite: exclude;
        }
    }
    .firebaseui-error-wrapper {
        margin-top: 8px;
    }
    .firebaseui-error {
        color: $color_contents_negative;
        font-weight: 500;
    }
    .firebaseui-title {
        font-size: 24px;
        font-weight: 500;
    }
    .firebaseui-card-content {
        margin-top: 16px;
        .firebaseui-textfield {
            margin-top: 32px;
        }
        .firebaseui-new-password-component > .firebaseui-textfield {
            margin-top: 16px;
        }
        .firebaseui-error-wrapper + .firebaseui-textfield {
            margin-top: 16px;
        }
    }
    .firebaseui-card-actions {
        margin-top: 24px;
        .firebaseui-form-actions {
            display: flex;
            flex-direction: column;
            margin-top: 32px;
        }
    }
    .firebaseui-card-footer {
        margin-top: 16px;
    }
    .firebaseui-id-page-sign-in .firebaseui-tos {
        color: $color_contents_secondary;
        font-size: 12px;
        line-height: 2;
    }
    .firebaseui-inline-list-item {
        display: inline-block;
        + .firebaseui-inline-list-item::before {
            content: "/";
            margin: 0 4px;
        }
    }
    .loading {
        display: grid;
        place-items: center;
        position: fixed;
        top: 0;
        visibility: visible;
        opacity: 1;
        width: 100%;
        height: 100svh;
        backdrop-filter: blur(10px);
        background-color: $color_background_overlay_dark_pale;
        text-align: center;
        transition: opacity .2s, visibility .2s;
        img {
            width: 100px;
        }
        .text {
            display: inline-block;
            margin-top: 4px;
            color: $color_contents_inverse;
        }
        &.hidden {
            visibility: hidden;
            opacity: 0;
        }
    }
    .firebaseui-idp-text-short {
        display: none;
    }
    .firebaseui-idp-list li + li {
        margin-top: 16px;
    }
    .firebaseui-idp-twitter,
    .firebaseui-idp-google,
    .firebaseui-idp-password,
    .firebaseui-idp-generic {
        position: relative;
        width: 100%;
        height: 48px;
        border-radius: 24px;
        cursor: pointer;
        .firebaseui-idp-icon {
            height: 24px;
        }
        .firebaseui-idp-icon-wrapper {
            position: absolute;
            left: 24px;
        }
        .firebaseui-idp-text {
            font-size: 16px;
            margin-left: 16px;
        }
    }
    .firebaseui-idp-twitter {
        border: none;
        color: $color_contents_inverse;
    }
    .firebaseui-idp-password {
        border: none;
        color: $color_contents_inverse;
        background: $color_background_grad;
    }
    .firebaseui-idp-generic {
        border: none;
        color: $color_contents_inverse;
        .firebaseui-idp-icon-wrapper {
            margin-top: -2px;
        }
    }
}
.modal_signin {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    display: grid;
    place-items: center;
    margin: 0;
    width: 100vw;
    height: 100vh;
    visibility: visible;
    opacity: 1;
    transition: opacity .2s, visibility .2s;
    text-align: center;
    .firebaseui-card-content:has(.firebaseui-idp-list) {
        margin-top: 0;
    }
    .emphasis {
        position: relative;
        display: inline-block;
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.6;
        strong {
            background: $color_contents_grad;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        &::before,
        &::after {
            position: absolute;
            top: 50%;
            content: "";
            display: block;
            width: 56px;
            height: 2px;
            border-radius: 2px;
            background: $color_contents_grad;
        }
        &::before {
            transform: translateY(-50%) rotate(70deg);
            left: -52px;
        }
        &::after {
            transform: translateY(-50%) rotate(110deg);
            right: -52px;
        }
    }
    .container {
        width: 100%;
        border: 1px solid #ddd;
        margin-top: 24px;
        padding: 24px 16px;
        text-align: left;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        backdrop-filter: blur(10px);
        background-color: $color_background_overlay_dark_pale;
        transition: opacity .2s, visibility .2s;
    }
    .inner {
        position: relative;
        width: 400px;
        max-width: calc(100vw - 32px);
        max-height: calc(100dvh - 32px);
        padding: 32px;
        border-radius: 10px;
        background-color: $color_background_primary;
        overflow-y: scroll;
    }
    h2 {
        display: inline-block;
        font-size: 24px !important;
        background: $color_contents_grad2;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    p {
        margin-top: 24px;
        font-weight: 500;
        line-height: 1.7;
        text-align: center;
    }
    .btn_close_wrap {
        position: fixed;
        width: 400px;
        max-width: calc(100vw - 32px);
    }
    .btn_close {
        float: right;
        transform: translateX(100%);
        margin-top: -88px;
        margin-right: 72px;
        border: none;
        cursor: pointer;
        font-size: 24px;
    }
}