@import "./variables";

.terms,
.privacy,
.tokutei,
.requirements {
  width: calc(100% - 64px);
  max-width: 640px;
  margin: 80px auto;
  padding: 32px;
  border-radius: 10px;
  background-color: $color_background_primary;
}
.terms,
.privacy,
.tokutei,
.requirements {
  section {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
  p, ul, ol {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  li {
    list-style: disc;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  p, li {
    line-height: 1.7;
  }
  ul, ol {
    padding-left: 2em;
  }
  h2 + section {
    margin-top: 16px;
  }
}