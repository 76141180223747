@import "./variables";

.paymentModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: grid;
  place-items: center;
  margin: 0;
  width: 100vw;
  height: 100vh;
  visibility: visible;
  opacity: 1;
  transition: opacity .2s, visibility .2s;
  text-align: center;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
    background-color: $color_background_overlay_dark_pale;
    transition: opacity .2s, visibility .2s;
  }
  .inner {
    position: relative;
    width: 400px;
    max-width: calc(100vw - 32px);
    max-height: calc(100dvh - 32px);
    padding: 32px;
    border-radius: 10px;
    background-color: $color_background_primary;
    overflow-y: scroll;
  }
  h2 {
    display: inline-block;
    font-size: 24px !important;
    background: $color_contents_grad2;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.7;
    text-align: center;
  }
  .btn_close_wrap {
    position: fixed;
    width: 400px;
    max-width: calc(100vw - 32px);
  }
  .btn_close {
    float: right;
    transform: translateX(100%);
    margin-top: -78px;
    margin-right: 72px;
    border: none;
    cursor: pointer;
    font-size: 24px;
  }
  .buttons {
    margin-top: 24px;
  }
  .buttons button {
    width: 100%;
    height: 55px;
    border: 1px solid $color_border;
    border-radius: 55px;
    cursor: pointer;
    &:disabled {
      background: $states_overlay_disabled_background;
      color: $states_overlay_disabled_oncolor;
      cursor: default;
    }
  }
  .button p {
    margin-top: 4px;
  }
  .button + .button {
    margin-top: 16px;
  }
  .line img {
    height: 24px;
  }
  .stripe img {
    height: 24px;
    + img {
      margin-left: 8px;
    }
  }
  .stripe span {
    margin: 0 8px;
    font-weight: 500;
  }
  .notification {
    width: 100%;
    margin-top: 24px;
    padding: 8px;
    background-color: #FFEDE0;
    border: 2px solid #CD7533;
    border-radius: 8px;
    p {
      text-align: left;
      + p {
        margin-top: 4px;
      }
    }
  }
}