@import "./variables";

.notification {
  opacity: 1;
  visibility: visible;
  position: fixed;
  top: 58px;
  z-index: 300;
  width: 100%;
  transition: opacity .2s, visibility .2s;
  pointer-events: none;
  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  .inner {
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: 280px;
    margin: auto;
    padding: 8px 12px;
    border-radius: 10px;
    background-color: #fff;
    font-size: 12px;
    font-weight: 500;
    box-shadow: 0 5px 25px rgba(0, 0, 0, .2);
  }
  
  .icon::before {
    color: $color_contents_accent;
    font-size: 24px;
  }

  .text {
    margin-left: 8px;
  }
}