.Apply {
  @media screen and (min-width: 601px) {
    font-size: 18px;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  section:not(:first-child) {
    @media screen and (min-width: 601px) {
      margin-top: 32px;
    }
    @media screen and (max-width: 600px) {
      margin-top: 16px;
    }
  }
  .btn {
    margin-top: 24px;
    @media screen and (min-width: 601px) {
      max-width: 344px;
    }
  }
  .btnPrimary,
  .btnSecondary,
  .btnTertiary {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
  }
  .btnPrimary:not(.navBtnSp),
  .btnSecondary {
    display: flex;
    justify-content: center;
    &::after {
      font-family: 'pazle';
      content: "\e909";
      display: block;
      font-size: 16px;
      margin-left: 2px;
    }
  }
  .btnSecondary {
    &::after {
      position: relative;
      z-index: 1;
      background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    @media screen and (min-width: 601px) {
      &:hover::after {
        background: linear-gradient(to right, #fff 0%, #fff 100%);
        background-clip: text;
      }
    }
  }
  .btnPrimary {
    width: 100%;
    position: relative;
    color: #fff;
    box-shadow: -8px 7px 15px rgba(0, 0, 0, .15);
    background: linear-gradient(to right, #FF5C83 0%, #FF927C 100%);
    border: none;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      background-color: #fff;
      opacity: 0;
      transition: opacity .2s;
    }
    @media screen and (min-width: 601px) {
      &:hover:before {
        opacity: .4;
      }
    }
  }
  &.hugTime .btnPrimary {
    background: linear-gradient(to right, #FF3395 0%, #FC7EFF 100%);
  }
  &.noroiNoRondo .btnPrimary {
    background: linear-gradient(to right, #1AD3A7 0%, #0EB1D4 100%);
  }
  &.kouenNoMaken .btnPrimary {
    background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
  }
  &.reply .btnPrimary {
    background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
  }
  .btnSecondary {
    position: relative;
    background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%) border-box border-box;
    box-shadow: -8px 7px 15px rgba(0, 0, 0, .15);
    span {
      position: relative;
      z-index: 1;
      background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: color .2s;
    }
    &:before {
      content: "";
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      background-color: #fff;
      border-radius: 9999px;
      transition: opacity .2s;
    }
    @media screen and (min-width: 601px) {
      &:hover span {
        -webkit-text-fill-color: inherit;
        color: #fff;
      }
      &:hover:before {
        opacity: 0;
      }
    }
  }
  .btnTertiary {
    border: 1px solid #7D7D7D;
    color: #2E2E2E;
    transition: color .2s, border .2s;
    @media screen and (min-width: 601px) {
      &:hover {
        border: 1px solid #9D76FF;
        color: #9D76FF;
      }
    }
  }
  .btnSmall {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    border-radius: 20px;
    font-size: 16px;
  }
  .btnMedium {
    padding: 0 16px;
    border-radius: 30px;
    @media screen and (min-width: 601px) {
      height: 60px;
      line-height: 60px;
      font-size: 20px;
    }
    @media screen and (max-width: 600px) {
      height: 40px;
      line-height: 40px;
      font-size: 16px;
    }
  }
  .btnLarge {
    padding: 0 16px;
    border-radius: 40px;
    @media screen and (min-width: 601px) {
      height: 80px;
      line-height: 80px;
      font-size: 24px;
    }
    @media screen and (max-width: 600px) {
      height: 60px;
      line-height: 60px;
      font-size: 20px;
    }
  }
  .purchase_img {
    display: inline-block;
    margin-top: 24px;
    transition: opacity .2s;
    cursor: pointer;
    @media screen and (min-width: 601px) {
      &:hover {
        opacity: .6;
      }
    }
    img {
      @media screen and (min-width: 601px) {
        max-width: 640px;
      }
      display: block;
      width: 100%;
      border-radius: 16px;
      
    }
  }
  
  h4 {
    margin-top: 0;
    margin-bottom: 8px;
    &:before {
      content: "";
      position: relative;
      top: 2px;
      display: inline-block;
      margin-right: 4px;
      border-radius: 9999px;
      background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
    }
    @media screen and (min-width: 601px) {
      font-size: 24px;
      &:before {
        width: 20px;
        height: 20px;
      }
    }
    @media screen and (max-width: 600px) {
      font-size: 18px;
      &:before {
        width: 16px;
        height: 16px;
      }
    }
  }
  ol,
  ul,
  p {
    @media screen and (min-width: 601px) {
      margin-top: 16px;
    }
    @media screen and (max-width: 600px) {
      margin-top: 8px;
    }
  }
  ol {
    padding-left: 1.5em;
  }
  ul {
    list-style: disc;
    padding-left: 1.5em;
  }
  li {
    margin-top: 4px;
  }
  li > p {
    margin-top: 4px;
  }
  .attention + .attention,
  p:not(.attention) + .attention {
    margin-top: 0;
  }
  .attention {
    color: #FF3D3D;
    @media screen and (min-width: 601px) {
      font-size: 14px;
    }
    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }
  .modal {
    position: fixed;
    top: 0;
    justify-content: center;
    align-items: center;
    z-index: 500;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 601px) {
      padding: 24px;
    }
    @media screen and (max-width: 600px) {
      padding: 16px;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
    }
    .inner {
      position: relative;
      z-index: 10;
      border-radius: 24px;
      background-color: #fff;
      box-sizing: border-box;
      overflow-y: scroll;
      @media screen and (min-width: 601px) {
        max-height: calc(100dvh - 48px);
        padding: 80px 32px 40px 32px;
      }
      @media screen and (max-width: 600px) {
        max-height: calc(100dvh - 32px);
        padding: 64px 24px 40px 24px;
      }
    }
  }
  .btnClose {
    position: fixed;
    z-index: 20;
    border: none;
    cursor: pointer;
    @media screen and (min-width: 601px) {
      top: 48px;
      right: 48px;
      font-size: 32px;
    }
    @media screen and (max-width: 600px) {
      top: 36px;
      right: 36px;
      font-size: 24px;
    }
  }
}