@import "./variables";

.streams {
    width: 100vw;
    height: 100dvh;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, .8);
    overflow: hidden;
    .contents_header {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        z-index: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        &::before {
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 64px;
            background: linear-gradient(to top, rgba(0, 2, 23, 0) 0%, rgba(0, 2, 23, 0.1) 20%, rgb(0, 2, 23, 0.6) 100%);
        }
        > * {
            z-index: 100;
        }
        .btn_close {
            position: absolute;
            left: 8px;
            display: grid;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border: none;
            border-radius: 16px;
            color: $color_contents_inverse;
            cursor: pointer;
            i {
                font-size: 24px;
            }
        }
        img {
            opacity: .5;
            height: 27px;
        }
        .login {
            position: absolute;
            right: 12px;
            display: flex;
            background-color: rgba(0,0,0,.5);
            padding: 4px 4px 4px 2px;
            border-radius: 4px;
            i {
                font-size: 20px;
                color: $color_contents_inverse;
                opacity: 0.4;
            }
            p {
                font-size: 8px;
                font-weight: 500;
                color: $color_contents_inverse;
                opacity: 0.4;
            }
        }
        button {
            position: absolute;
            right: 16px;
        }
    }
    .video_wrap {
        position: relative;
        width: fit-content;
        height: fit-content;
        width: 100vw;
        height: 100dvh;
        background-color: #000;
        @media screen and (min-width: 600px) {
            top: 16px;
            width: auto;
            max-width: 100vw;
            height: calc(100dvh - 32px);
            margin: auto;
            aspect-ratio: 9 / 16;
        }
    }
    .video {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        audio {
            margin-top: 24px;
        }
    }
    .video img {
        width: 100%;
    }
    .contents_footer_btns {
        position: absolute;
        right: 24px;
        bottom: 24px;
        left: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .btn_relation {
        height: 40px;
        margin-left: 16px;
        padding: 0 16px;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 300;
        display: grid;
        place-items: center;
        margin: 0;
        width: 100vw;
        height: 100vh;
        visibility: visible;
        opacity: 1;
        transition: opacity .2s, visibility .2s;
        text-align: center;
        .container {
            width: 100%;
            border: 1px solid #ddd;
            margin-top: 24px;
            padding: 24px 16px;
            text-align: left;
        }
        img {
            width: 100%;
            margin: 24px 0;
            border-radius: 16px;
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100vw;
            height: 100vh;
            backdrop-filter: blur(10px);
            background-color: $color_background_overlay_dark_pale;
            transition: opacity .2s, visibility .2s;
        }
        .inner {
            position: relative;
            width: 400px;
            max-width: calc(100vw - 32px);
            max-height: calc(100dvh - 32px);
            padding: 32px;
            border-radius: 10px;
            background-color: $color_background_primary;
            overflow-y: scroll;
        }
        h2 {
            display: inline-block;
            font-size: 24px !important;
            background: $color_contents_grad2;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        p {
            margin-top: 24px;
            font-weight: 500;
            line-height: 1.7;
            text-align: center;
        }
        .btn_close_wrap {
            position: fixed;
            width: 400px;
            max-width: calc(100vw - 32px);
        }
        .btn_close {
            float: right;
            transform: translateX(100%);
            margin-top: -88px;
            margin-right: 72px;
            border: none;
            cursor: pointer;
            font-size: 24px;
        }
    }
    .modal_relation {
        p {
            margin-top: 0;
            text-align: left;
        }
        img {
            margin: 0;
        }
        h3 {
            font-size: 16px;
        }
        .btn_close {
            margin-top: -58px;
        }
        .modal_relation_contents {
            margin-top: 24px;
            li + li {
                margin-top: 16px;
            }
        }
        .modal_relation_footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;
        }
        .modal_relation_txt {
            p {
                color: $color_contents_secondary;
                font-size: 11px;
            }
        }
        .modal_relation_btn {
            height: 40px;
            padding: 0 16px;
        }
    }
    .announce_ended_video {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 16px;
        backdrop-filter: blur(10px);
        background-color: $color_background_overlay_dark;
    }
}