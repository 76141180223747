@import "./variables";

.contentsDetail {
  max-width: 640px;
  padding: 80px 32px 32px;
  margin: auto;

  pre, p {
    margin: 0;
    line-height: 1.5;
  }
  pre {
    white-space: pre-wrap;
  }
  .thumbnail {
    width: 100%;
    border-radius: 16px;
  }
  h2 {
    margin-top: 16px;
    font-size: 16px;
  }
  h3 {
    color: $color_contents_secondary;
    font-size: 18px;
  }
  h3 + * {
    margin-top: 12px;
  }
  section {
    margin-top: 32px;
  }
  .chapter {
    color: $color_contents_secondary;
    font-size: 11px;
  }
  .chapterList {
    margin-top: 32px;
    border-bottom: 1px solid $color_border;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      border-top: 1px solid $color_border;
    }
    .chapterTitle {
      font-size: 16px;
      font-weight: 500;
      
    }
    .chapterTime {
      color: $color_contents_secondary;
      font-size: 11px;
    }
  }
  .buttonBulk + .chapterList {
    margin-top: 16px;
  }
  .buttonBulk {
    margin-top: 32px;
    button {
      width: 100%;
    }
  }
  .actorList {
    li + li {
      margin-top: 4px;
    }
  }
  .buttons {
    display: flex;
    width: 167px;
  }
  .buttons button {
    height: 40px !important;
    width: 100%;
  }
  .buttonSecondary {
    width: 100%;
  }
  .buttonSecondary button {
    color: $color_contents_primary !important;
  }
  .buttonSecondary + button {
    margin-left: 8px;
  }
  .relatedWorksImg {
    display: block;
    img {
      width: 100%;
      border-radius: 16px;
    }
  }

  .loading_video {
    display: grid;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color_background_overlay_dark;
    color: $color_contents_inverse;
  }

  .streamList {
    li + li {
      margin-top: 12px;
    }
  }
}