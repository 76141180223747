@import "./variables";

.Maintenance {
  height: 100dvh;
  padding: 32px 32px 0;
  text-align: center;
  .logo {
    display: block;
    width: 160px;
    margin: auto;
  }
  .inner {
    max-width: 640px;
    margin: 32px auto 0;
    padding: 32px;
    border-radius: 10px;
    background-color: $color_background_primary;
  }
  h1 {
    font-size: 20px;
  }
  .inner img {
    display: block;
    max-width: 140px;
    margin: 24px auto 0;
  }
  p {
    display: inline-block;
    margin-top: 24px;
    text-align: left;
  }
  .footer {
    position: sticky;
    top: 100vh;
    width: 100%;
    margin-top: 32px;
    padding-bottom: 32px;
    a {
      color: #878787;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    li {
      margin: 4px 8px;
    }
  }
}