@import "./variables";

.Interviews {
  .btn_secondary {
    position: relative;
    display: block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: none;
    color: $color_contents_primary;
    border-radius: 20px;
    text-decoration: none;
    text-align: center;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid transparent;
        border-radius: 20px;
        background: $color_background_grad border-box border-box;
        mask: linear-gradient($color_background_primary 0 0) padding-box, linear-gradient($color_background_primary 0 0) border-box;
        mask-composite: exclude;
    }
  }
  .interviews {
    padding: 77px 24px 56px;
    scroll-snap-type: y mandatory;
    scroll-snap-align: center;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100dvh;
  }
  .interviews_item {
    width: 100%;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 16px;
    background-color: $color_background_primary;
    box-shadow: 0 0 60px rgba(21, 0, 70, .17);
    margin-top: 6dvh;
    margin-bottom: 6dvh;
    scroll-snap-align: center;
    &.current {
      position: relative;
      &::before,
      &::after {
          position: absolute;
          font-family: 'pazle' !important;
          content: "\e909";
          color: $color_contents_inverse;
          font-size: 3vh;
      }
      &::before {
          top: -5.5vh;
          left: 50%;
          transform: translateX(-50%) rotate(-90deg);
      }
      &::after {
          bottom: -5.5vh;
          left: 50%;
          transform: translateX(-50%) rotate(90deg);
      }
      &:first-child {
          &::before {
              display: none;
          }
      }
      &:last-child {
          &::after {
              display: none;
          }
      }
    }
    .thumbnail {
      position: relative;
    }
    .thumbnail img {
        width: 100%;
        border-radius: 8px 8px 0 0;
    }
    .item_text {
      padding: 16px;
    }
    .title {
      font-weight: 500;
      line-height: 1.6;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    .btn{
      margin-top: 12px;
    }
  }
}