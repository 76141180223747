html {
  scroll-behavior: smooth;
}

.Content {
  font-family: 'Ubuntu', 'M PLUS Rounded 1c', sans-serif;
  background-color: #fff;
  letter-spacing: 0;
  background-color: #fff;
  letter-spacing: 0;
  overflow: hidden;

  &.kouenNoMaken {
    background-color: #F5F3EE;
  }

  p {
    font-size: 14px;
    color: #2e2e2e;
    font-weight: 400;
    line-height: 1.6;

    @media screen and (min-width: 601px) {
      font-size: 18px;
    }

    strong {
      font-weight: 700;
    }

    +p {
      margin-top: 24px;
    }
  }

  .title {
    line-height: 1;
    text-align: center;

    +* {
      margin-top: 80px;
    }
  }

  .titleEn {
    color: #2e2e2e;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.4;

    @media screen and (min-width: 601px) {
      font-size: 70px;
    }
  }

  .titleJa {
    display: block;
    color: #7d7d7d;
    font-size: 12px;
    font-weight: 400;

    @media screen and (min-width: 601px) {
      font-size: 18px;
    }
  }

  .title .supplement {
    background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;

    @media screen and (min-width: 601px) {
      font-size: 16px;
    }

    @media screen and (max-width: 600px) {
      font-size: 12px;
    }
  }

  &.noroiNoRondo .title .supplement {
    background: linear-gradient(to right, #1AD3A7 0%, #0EB1D4 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.hugTime .title .supplement {
    background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.kouenNoMaken .title .supplement {
    background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.reply .title .supplement {
    background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.kurodaSan .title .supplement {
    background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.oshiKatsu .title .supplement {
    background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.melody .title .supplement {
    background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .subTitle {
    font-weight: 800;
    letter-spacing: 0.05em;

    @media screen and (min-width: 601px) {
      font-size: 37px;

      span {
        font-size: 23px;
      }
    }

    @media screen and (max-width: 600px) {
      font-size: 24px;

      span {
        font-size: 14px;
      }
    }

    strong {
      background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;

      @media screen and (min-width: 601px) {
        font-size: 60px;
      }
    }

    small {
      @media screen and (min-width: 601px) {
        font-size: 37px;
      }

      @media screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  &.noroiNoRondo .subTitle strong {
    background: linear-gradient(to right, #1AD3A7 0%, #0EB1D4 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.hugTime .subTitle strong {
    background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.kouenNoMaken .subTitle strong {
    background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.reply .subTitle strong {
    background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.kurodaSan .subTitle strong {
    background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.melody .subTitle strong {
    background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.oshiKatsu .subTitle strong {
    background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .btnPrimary,
  .btnSecondary,
  .btnTertiary {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
  }

  .btnPrimary:not(.navBtnSp),
  .btnSecondary {
    display: flex;
    justify-content: center;

    &::after {
      font-family: 'pazle';
      content: "\e909";
      display: block;
      font-size: 16px;
      margin-left: 2px;
    }
  }

  .btnSecondary {
    &::after {
      position: relative;
      z-index: 1;
      background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @media screen and (min-width: 601px) {
      &:hover::after {
        background: linear-gradient(to right, #fff 0%, #fff 100%);
        background-clip: text;
      }
    }
  }

  .btnPrimary {
    position: relative;
    color: #fff;
    box-shadow: -8px 7px 15px rgba(0, 0, 0, .15);
    background: linear-gradient(to right, #FF5C83 0%, #FF927C 100%);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      background-color: #fff;
      opacity: 0;
      transition: opacity .2s;
    }

    @media screen and (min-width: 601px) {
      &:hover:before {
        opacity: .4;
      }
    }
  }

  .btnSecondary {
    position: relative;
    background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%) border-box border-box;
    box-shadow: -8px 7px 15px rgba(0, 0, 0, .15);
    color: transparent;

    span {
      position: relative;
      z-index: 1;
      background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: color .2s;
    }

    &:before {
      content: "";
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      background-color: #fff;
      border-radius: 9999px;
      transition: opacity .2s;
    }

    @media screen and (min-width: 601px) {
      &:hover span {
        -webkit-text-fill-color: inherit;
        color: #fff;
      }

      &:hover:before {
        opacity: 0;
      }
    }
  }

  &.noroiNoRondo {
    .btnPrimary {
      background: linear-gradient(to right, #FF5A44 0%, #FFA044 100%);
    }

    .btnSecondary {
      background: linear-gradient(to right, #14BBDF 0%, #1AD3A7 100%);

      &::after {
        background: linear-gradient(to right, #14BBDF 0%, #1AD3A7 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }

      span {
        background: linear-gradient(to right, #14BBDF 0%, #1AD3A7 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }

    .btnTertiary {
      @media screen and (min-width: 601px) {
        &:hover {
          border: 1px solid #14BBDF;
          color: #14BBDF;
        }
      }
    }
  }

  &.hugTime {
    .btnPrimary {
      background: linear-gradient(to right, #FF3395 0%, #FC7EFF 100%);
    }

    .btnSecondary {
      background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);

      &::after {
        background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }

      span {
        background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }

    .btnTertiary {
      @media screen and (min-width: 601px) {
        &:hover {
          border: 1px solid #EF2CCE;
          color: #EF2CCE;
        }
      }
    }
  }

  &.kouenNoMaken {
    .btnPrimary {
      background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
    }

    .btnSecondary {
      background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);

      &::after {
        background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }

      span {
        background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }

    .btnTertiary {
      @media screen and (min-width: 601px) {
        &:hover {
          border: 1px solid #FF7A00;
          color: #FF7A00;
        }
      }
    }
  }

  &.reply {
    .btnPrimary {
      background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
    }

    .btnSecondary {
      background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);

      &::after {
        background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }

      span {
        background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }

    .btnTertiary {
      @media screen and (min-width: 601px) {
        &:hover {
          border: 1px solid #00A3FF;
          color: #00A3FF;
        }
      }

      &:disabled {
        background-color: #D3D3D3;
        border: none;
        color: #fff;
      }
    }
  }

  &.kurodaSan {
    .btnPrimary {
      background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
    }

    .btnSecondary {
      background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);

      &::after {
        background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }

      span {
        background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }

    .btnTertiary {
      @media screen and (min-width: 601px) {
        &:hover {
          border: 1px solid #886AFF;
          color: #886AFF;
        }
      }

      &:disabled {
        background-color: #D3D3D3;
        border: none;
        color: #fff;
      }
    }
  }

  &.oshiKatsu {
    .btnPrimary {
      background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
    }

    .btnSecondary {
      background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);

      &::after {
        background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }

      span {
        background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }

    .btnTertiary {
      @media screen and (min-width: 601px) {
        &:hover {
          border: 1px solid #FF6F9F;
          color: #FF6F9F;
        }
      }

      &:disabled {
        background-color: #D3D3D3;
        border: none;
        color: #fff;
      }
    }
  }

  &.melody {
    .btnPrimary {
      background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
    }

    .btnSecondary {
      background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);

      &::after {
        background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }

      span {
        background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @media screen and (min-width: 601px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }

    .btnTertiary {
      @media screen and (min-width: 601px) {
        &:hover {
          border: 1px solid #F2779C;
          color: #F2779C;
        }
      }

      &:disabled {
        background-color: #D3D3D3;
        border: none;
        color: #fff;
      }
    }
  }

  &.melody .subTitle strong {
    background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .btnTertiary {
    border: 1px solid #7D7D7D;
    color: #2E2E2E;
    transition: color .2s, border .2s;

    @media screen and (min-width: 601px) {
      &:hover {
        border: 1px solid #9D76FF;
        color: #9D76FF;
      }
    }
  }

  .btnSmall {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    border-radius: 20px;
    font-size: 16px;
  }

  .btnMedium {
    padding: 0 16px;
    border-radius: 30px;

    @media screen and (min-width: 601px) {
      height: 60px;
      line-height: 60px;
      font-size: 20px;
    }

    @media screen and (max-width: 600px) {
      height: 40px;
      line-height: 40px;
      font-size: 16px;
    }
  }

  .btnLarge {
    padding: 0 16px;
    border-radius: 40px;

    @media screen and (min-width: 601px) {
      height: 80px;
      line-height: 80px;
      font-size: 24px;
    }

    @media screen and (max-width: 600px) {
      height: 60px;
      line-height: 60px;
      font-size: 20px;
    }
  }

  .brPc {
    @media screen and (min-width: 601px) {
      display: block;
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .brSp {
    display: block;

    @media screen and (min-width: 601px) {
      display: none;
    }

    @media screen and (max-width: 600px) {
      display: block;
    }
  }

  .loading {
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s, visibility .3s;

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100vw;
      height: 100vh;
      background: linear-gradient(135deg, #0075ab26 10%, #ff7eff41 40%, #FCF565 80%);
      opacity: .36;
    }

    img {
      width: 240px;
    }

    p {
      margin-top: 16px;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
    }

    .word {
      display: inline-block;
      opacity: 0;
      scale: 0;

      @for $i from 1 to 14 {
        &:nth-child(#{$i}) {
          animation: fadeInText .4s cubic-bezier(.47, 1.64, .41, .8) 0.1 * $i + s forwards;
        }
      }

      @keyframes fadeInText {
        100% {
          opacity: 1;
          scale: 1;
        }
      }
    }

    .wordFirstLetter {
      margin-left: 4px;
    }
  }

  .header {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    padding: 24px 24px 0;

    @media screen and (min-width: 601px) {
      padding: 0 32px 0;
    }

    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (min-width: 601px) {
        width: 100%;
        max-width: 1240px;
        margin: auto;
        padding-top: 32px;
      }
    }

    .navBtnSp {
      position: fixed;
      bottom: 24px;
      right: 24px;
      display: grid;
      align-items: center;
      justify-content: center;
      width: 92px;
      height: 92px;
      padding: 0;
      border-radius: 46px;
      background: linear-gradient(to right, #FF5C83 0%, #FF927C 100%);
      font-size: 12px;
      font-weight: 500;
      line-height: 1.6;
      text-align: center;

      @media screen and (min-width: 601px) {
        display: none;
      }
    }

    .pageToSns {
      display: block;
      height: 35px;
      line-height: 35px;
      padding: 0 16px;
      border-radius: 18px;
      background-color: #fff;
      color: #2e2e2e;
      font-size: 14px;
      text-decoration: none;
      box-shadow: -8px 7px 15px rgba(0, 0, 0, 0.15);
      transition: color .2s;

      @media screen and (min-width: 768px) {
        display: none;
      }

      i {
        position: relative;
        top: 2px;
        margin-right: 4px;
        font-size: 16px;
        background: linear-gradient(to right, #FF5C83 0%, #FF927C 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .headerBtns {
      display: none;

      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }
    }

    .btnTw {
      display: none;
      height: 35px;
      line-height: 35px;
      padding: 0 10px;
      border-radius: 18px;
      background-color: #000;
      color: #fff;
      font-size: 14px;
      text-decoration: none;
      box-shadow: -8px 7px 15px rgba(0, 0, 0, 0.15);
      transition: background-color .2s;

      &:before {
        content: "";
        display: inline-block;
        position: relative;
        top: 4px;
        width: 18px;
        height: 18.4px;
        margin-right: 2px;
        background-image: url("../images/x_white.svg");
        background-size: 100%;
      }

      &:hover {
        background-color: #4d4d4d;
      }

      @media screen and (min-width: 768px) {
        display: inline-block;
      }
    }

    .btnLine {
      position: relative;
      display: none;
      margin-left: 16px;
      border-radius: 18px;
      border: 1px solid #fff;
      box-shadow: -8px 7px 15px rgba(0, 0, 0, 0.15);
      overflow: hidden;

      img {
        height: 35px;
        transition: opacity .2s;

        &:hover {
          opacity: .6;
        }
      }

      @media screen and (min-width: 768px) {
        display: inline-block;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
      }
    }

    .navBtnPc {
      @media screen and (min-width: 601px) {
        width: 230px;
        margin-left: 16px;
      }

      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    .logo {
      height: 35px;

      @media screen and (min-width: 601px) {
        height: 48px;
      }
    }
  }

  &.noroiNoRondo header {
    .navBtnSp {
      background: linear-gradient(to right, #FF5A44 0%, #FFA044 100%);
    }

    .pageToSns i {
      background: linear-gradient(to right, #FF5A44 0%, #FFA044 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.hugTime header {
    .navBtnSp {
      background: linear-gradient(to right, #FF3395 0%, #FC7EFF 100%);
    }

    .pageToSns i {
      background: linear-gradient(to right, #FF3395 0%, #FC7EFF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.kouenNoMaken header {
    .navBtnSp {
      background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
    }

    .pageToSns i {
      background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.reply header {
    .navBtnSp {
      background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
    }

    .pageToSns i {
      background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.kurodaSan header {
    .navBtnSp {
      background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
    }

    .pageToSns i {
      background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.oshiKatsu header {
    .navBtnSp {
      background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
    }

    .pageToSns i {
      background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.melody header {
    .navBtnSp {
      background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
    }

    .pageToSns i {
      background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  main>section {
    @media screen and (min-width: 601px) {
      padding-top: 230px;
    }

    @media screen and (max-width: 600px) {
      padding-top: 120px;
    }
  }

  .mainvisual {
    position: relative;

    .mainvisualImg {
      width: 100%;
    }

    .scroll {
      @media screen and (min-width: 601px) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        overflow: hidden;
        pointer-events: none;

        &::before {
          content: "";
          position: absolute;
          z-index: 0;
          bottom: 80px;
          left: 50%;
          transform: translateX(-50%);
          width: 1px;
          height: 0;
          background: #fff;
          animation: moveBorder 1.5s infinite;
        }
      }
    }

    @media screen and (min-width: 601px) {
      &::before {
        content: "";
        position: absolute;
        z-index: 0;
        bottom: 80px;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        height: 0;
        background: linear-gradient(to bottom, #4D9AE4 0%, #BD41FF 100%);
        animation: moveBorder 1.5s infinite;
      }
    }

    @keyframes moveBorder {
      0% {
        bottom: 80px;
        height: 0;
      }

      50% {
        bottom: -40px;
        height: 80px;
      }

      100% {
        bottom: -80px;
        height: 0px;
      }
    }

    .inner {
      position: absolute;
      z-index: 10;

      @media screen and (min-width: 601px) {
        left: 7vw;
        bottom: 3vw;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        bottom: 48px;
        padding: 0 24px;
      }
    }

    h1 img {
      @media screen and (min-width: 601px) {
        width: 30vw;
      }

      @media screen and (max-width: 600px) {
        width: calc(100vw - 32px);
      }
    }

    .tag {
      width: 100%;
      line-height: 1.66;
      letter-spacing: 0.08em;
      font-weight: 500;

      @media screen and (min-width: 601px) {
        margin-top: 1.4vw;
        font-size: clamp(12px, 0.98vw, 14px);
        font-weight: 500;
      }

      @media screen and (max-width: 600px) {
        margin-top: 24px;
        font-size: 14px;
      }

      &.inverse {
        color: #fff;
      }
    }

    .btn {
      @media screen and (min-width: 601px) {
        width: 30vw;
        height: 5.5vw;
        line-height: 5.5vw;
        margin-top: 1.4vw;
        border-radius: 5.5vw;
        font-size: clamp(13px, 1.6vw, 24px);
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        margin-top: 24px;
        font-size: 20px;
      }
    }

    .mainvisualImgSp {
      display: block;

      @media screen and (min-width: 601px) {
        display: none;
      }
    }

    .mainvisualImgPc {
      display: none;

      @media screen and (min-width: 601px) {
        display: block;
      }
    }

    .actorPc {
      position: absolute;
      bottom: -6.8vw;
      right: 0;
      width: 31vw;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    .mainvisualCsp {
      img {
        width: 86px;
      }

      p {
        font-size: 16px;
        color: #fff;
        line-height: 1.3;
        margin-left: 16px;

        @media screen and (min-width:768px) {
          margin-left: 0;
        }
      }

      &.cspSp {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;

        @media screen and (min-width:768px) {
          display: none;
        }
      }

      &.cspPc {
        display: none;

        @media screen and (min-width:768px) {
          display: block;
          margin-top: 1.6vw;

          img {
            width: 272px;
          }

          p {
            margin-top: 0.5vw;
            font-size: clamp(12px, 1.1vw, 16px);
          }
        }
      }
    }
  }

  &.noroiNoRondo .mainvisual {
    &::before {
      background: linear-gradient(to bottom, #1AD3A7 0%, #0EB1D4 100%);
    }

    h1 img {
      @media screen and (min-width: 601px) {
        width: 32vw;
      }

      @media screen and (max-width: 600px) {
        width: 65vw;
      }
    }

    .mainvisualCsp {
      p {
        font-size: 16px;
        color: #2E2E2E;
      }
    }
  }

  &.hugTime .mainvisual {
    &::before {
      background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
    }

    h1 img {
      @media screen and (min-width: 601px) {
        width: 32vw;
      }

      @media screen and (max-width: 600px) {
        width: 65vw;
      }
    }

    .mainvisualCsp {
      p {
        font-size: 16px;
        color: #2E2E2E;
      }
    }
  }

  &.kouenNoMaken .mainvisual {
    &::before {
      background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
    }

    h1 img {
      @media screen and (min-width: 601px) {
        position: absolute;
        top: -27vw;
        left: 48vw;
        width: 39vw;
      }

      @media screen and (max-width: 600px) {
        width: 70vw;
        display: block;
        margin-right: 0;
        margin-left: auto;
      }
    }

    .actorPc {
      position: absolute;
      right: 10vw;
      top: 36vw;
      width: 30vw;
    }

    .mainvisualCsp {
      p {
        font-size: 16px;
        color: #2E2E2E;
      }
    }
  }

  &.reply .mainvisual {
    .inner {
      display: flex;

      @media screen and (min-width: 601px) {
        align-items: flex-end;
      }

      @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
      }
    }

    .free {
      display: grid;
      align-items: center;
      justify-content: center;
      margin-left: 1vw;
      border: 2px solid #fff;
      background: linear-gradient(135deg, rgba(85, 102, 255, .7) 0%, rgba(255, 103, 222, .7) 100%);

      @media screen and (min-width: 601px) {
        width: 12vw;
        height: 12vw;
        border-radius: 6vw;
      }

      @media screen and (max-width: 600px) {
        width: 31vw;
        height: 31vw;
        margin-right: 0;
        margin-left: auto;
        border-radius: 16vw;
      }

      p {
        font-family: 'Avenir Next', 'IBM Plex Sans JP', sans-serif;
        color: #fff;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        letter-spacing: 2px;
        margin-top: -5px;

        strong {
          font-style: italic;
        }

        span {
          display: flex;
          align-items: center;
        }

        @media screen and (min-width: 601px) {
          font-size: clamp(14px, 3.1vw, 45px);

          strong {
            font-size: clamp(16px, 4.1vw, 60px);
          }

          small {
            font-size: clamp(12px, 1.7vw, 25px);
          }
        }

        @media screen and (max-width: 600px) {
          font-size: clamp(14px, 8.2vw, 32px);

          strong {
            font-size: clamp(16px, 10.7vw, 42px);
          }

          small {
            font-size: clamp(12px, 4.6vw, 18px);
          }
        }
      }
    }

    &::before {
      background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
    }
  }

  &.kurodaSan .mainvisual {
    img {
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .inner {
      display: flex;

      @media screen and (min-width: 601px) {
        align-items: flex-end;
      }

      @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
      }
    }

    .free {
      display: grid;
      align-items: center;
      justify-content: center;
      margin-left: 1vw;
      border: 2px solid #fff;
      background: linear-gradient(135deg, rgba(212, 255, 50, .7) 0%, rgba(96, 187, 239, .7) 100%);

      @media screen and (min-width: 601px) {
        width: 12vw;
        height: 12vw;
        border-radius: 6vw;
      }

      @media screen and (max-width: 600px) {
        width: 31vw;
        height: 31vw;
        margin-right: 0;
        margin-left: auto;
        border-radius: 16vw;
      }

      p {
        font-family: 'Avenir Next', 'IBM Plex Sans JP', sans-serif;
        color: #fff;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        letter-spacing: 2px;
        margin-top: -5px;

        strong {
          font-style: italic;
        }

        span {
          display: flex;
          align-items: center;
        }

        @media screen and (min-width: 601px) {
          font-size: clamp(14px, 3.1vw, 45px);

          strong {
            font-size: clamp(16px, 4.1vw, 60px);
          }

          small {
            font-size: clamp(12px, 1.7vw, 25px);
          }
        }

        @media screen and (max-width: 600px) {
          font-size: clamp(14px, 8.2vw, 32px);

          strong {
            font-size: clamp(16px, 10.7vw, 42px);
          }

          small {
            font-size: clamp(12px, 4.6vw, 18px);
          }
        }
      }
    }

    &::before {
      background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
    }
  }

  &.melody .mainvisual {
    img {
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .inner {
      display: flex;

      @media screen and (min-width: 601px) {
        align-items: flex-end;
      }

      @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
      }
    }

    .free {
      display: grid;
      align-items: center;
      justify-content: center;
      margin-left: 1vw;
      border: 2px solid #fff;
      background: linear-gradient(135deg, rgba(228, 140, 158, .7) 0%, rgba(165, 152, 211, .7) 100%);

      @media screen and (min-width: 601px) {
        width: 12vw;
        height: 12vw;
        border-radius: 6vw;
      }

      @media screen and (max-width: 600px) {
        width: 31vw;
        height: 31vw;
        margin-right: 0;
        margin-left: auto;
        border-radius: 16vw;
      }

      p {
        font-family: 'Avenir Next', 'IBM Plex Sans JP', sans-serif;
        color: #fff;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        letter-spacing: 2px;
        margin-top: -5px;

        strong {
          font-style: italic;
        }

        span {
          display: flex;
          align-items: center;
        }

        @media screen and (min-width: 601px) {
          font-size: clamp(14px, 3.1vw, 45px);

          strong {
            font-size: clamp(16px, 4.1vw, 60px);
          }

          small {
            font-size: clamp(12px, 1.7vw, 25px);
          }
        }

        @media screen and (max-width: 600px) {
          font-size: clamp(14px, 8.2vw, 32px);

          strong {
            font-size: clamp(16px, 10.7vw, 42px);
          }

          small {
            font-size: clamp(12px, 4.6vw, 18px);
          }
        }
      }
    }

    &::before {
      background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
    }
  }

  &.oshiKatsu .mainvisual {
    img {
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .inner {
      display: flex;

      @media screen and (min-width: 601px) {
        align-items: flex-end;
      }

      @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
      }
    }

    .free {
      display: grid;
      align-items: center;
      justify-content: center;
      margin-left: 1vw;
      border: 2px solid #fff;
      background: linear-gradient(135deg, rgba(245, 254, 100, .7) 0%, rgba(277, 132, 86, .7) 100%);

      @media screen and (min-width: 601px) {
        width: 12vw;
        height: 12vw;
        border-radius: 6vw;
      }

      @media screen and (max-width: 600px) {
        width: 31vw;
        height: 31vw;
        margin-right: auto;
        margin-left: 0;
        border-radius: 16vw;
      }

      p {
        font-family: 'Avenir Next', 'IBM Plex Sans JP', sans-serif;
        color: #fff;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        letter-spacing: 2px;
        margin-top: -5px;

        strong {
          font-style: italic;
        }

        span {
          display: flex;
          align-items: center;
        }

        @media screen and (min-width: 601px) {
          font-size: clamp(14px, 3.1vw, 45px);

          strong {
            font-size: clamp(16px, 4.1vw, 60px);
          }

          small {
            font-size: clamp(12px, 1.7vw, 25px);
          }
        }

        @media screen and (max-width: 600px) {
          font-size: clamp(14px, 8.2vw, 32px);

          strong {
            font-size: clamp(16px, 10.7vw, 42px);
          }

          small {
            font-size: clamp(12px, 4.6vw, 18px);
          }
        }
      }
    }

    &::before {
      background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
    }
  }

  .actor {
    padding-top: 64px;
    padding-left: 24px;
    padding-right: 24px;
    background-image: url(../images/content/treatment.png);
    background-position: -8px 0px;
    background-size: 100px 122px;

    @media screen and (min-width: 601px) {
      display: none;
    }

    img {
      display: block;
      width: 203px;
      margin: auto;
    }

    li+li {
      margin-top: 80px;
    }

    .text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      width: fit-content;
      margin: 8px auto 0;

      p {
        display: inline-block;
        font-weight: 500;
      }

      p+p {
        margin-top: 0;
      }

      .role {
        font-size: 12px;
      }

      .name {
        font-size: 18px;

        >small {
          font-size: 10px;
          margin-left: 4px;
        }
      }
    }
  }

  &.noroiNoRondo .actor {
    background-image: url(../images/content/noroi-no-rondo/treatment.png);
  }

  &.hugTime .actor {
    background-image: url(../images/content/hug-time/treatment.png);
  }

  &.kouenNoMaken .actor {
    background: none;
  }

  &.reply .actor {
    background-image: url(../images/content/fuyuzora-melt-reply/treatment.png);
  }

  &.kurodaSan .actor {
    background-image: url(../images/content/kuroda-san/treatment.png);
  }

  &.oshikatsu .actor {
    background-image: url(../images/content/oshi-katsu/treatment.png);
  }

  &.melody .actor {
    background-image: url(../images/content/melody/treatment.png);
  }

  .story {
    @media screen and (min-width: 601px) {
      position: relative;
      margin-top: -80px;
      padding-top: 310px;
      padding-left: 32px;
      padding-right: 32px;
      background-image: url(../images/content/treatment.png), url(../images/content/treatment.png);
      background-position: -20px 0px, right -70px top 550px;
      background-size: 266px 323px;
    }

    @media screen and (max-width: 600px) {
      padding-left: 24px;
      padding-right: 24px;
      background-image: url(../images/content/treatment.png);
      background-position: right -8px top 100px;
      background-size: 100px 122px;
    }

    .inner {
      @media screen and (min-width: 601px) {
        display: flex;
        width: 100%;
        max-width: 1116px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .imgWrap {
      @media screen and (min-width: 601px) {
        max-width: 564px;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    img {
      width: 100%;
    }

    .body {
      @media screen and (min-width: 601px) {
        max-width: 494px;
        margin-left: 56px;
      }

      img {
        @media screen and (min-width: 601px) {
          width: 100%;
        }

        @media screen and (max-width: 600px) {
          display: block;
          width: calc(100% - 80px);
          margin: 32px auto 0;
        }
      }

      p {
        font-weight: 500;

        @media screen and (min-width: 601px) {
          margin-top: 32px;
          font-size: 18px;
        }

        @media screen and (max-width: 600px) {
          margin-top: 32px;
        }
      }
    }

    .btnWrap {
      text-align: center;

      @media screen and (min-width: 601px) {
        margin-top: 64px;
      }

      @media screen and (max-width: 600px) {
        margin-top: 48px;
      }

      p {
        @media screen and (min-width: 601px) {
          max-width: 373px;
        }
      }
    }

    .btnWrap p {
      background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-left: auto;
      margin-right: auto;

      @media screen and (min-width: 601px) {
        font-size: 16px;
      }

      @media screen and (max-width: 600px) {
        font-size: 12px;
      }
    }

    .btn {
      margin-top: 8px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;

      @media screen and (min-width: 601px) {
        max-width: 373px;
      }
    }
  }

  &.noroiNoRondo .story {
    background-image: url(../images/content/noroi-no-rondo/treatment.png), url(../images/content/noroi-no-rondo/treatment.png);
  }

  &.hugTime .story {
    background-image: url(../images/content/hug-time/treatment.png), url(../images/content/hug-time/treatment.png);
  }

  &.kouenNoMaken .story {
    background: none;
  }

  &.reply .story {
    background-image: url(../images/content/fuyuzora-melt-reply/treatment.png), url(../images/content/fuyuzora-melt-reply/treatment.png);
  }

  &.kurodaSan .story {
    background-image: url(../images/content/kuroda-san/treatment.png), url(../images/content/kuroda-san/treatment.png);
  }

  &.oshiKatsu .story {
    background-image: url(../images/content/oshi-katsu/treatment.png), url(../images/content/oshi-katsu/treatment.png);
  }

  &.melody .story {
    background-image: url(../images/content/melody/treatment.png), url(../images/content/melody/treatment.png);
  }

  &.noroiNoRondo .story .btnWrap p {
    background: linear-gradient(to right, #1AD3A7 0%, #0EB1D4 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.hugTime .story .btnWrap p {
    background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.kouenNoMaken .story .btnWrap p {
    background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
    ;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.reply .story .btnWrap p {
    background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.kurodaSan .story .btnWrap p {
    background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.oshiKatsu .story .btnWrap p {
    background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.melody .story .btnWrap p {
    background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .introduction {
    padding: 32px 24px;
    background-color: #fff;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, .1);
    border-radius: 16px;
    margin-top: 24px;

    p {
      font-size: 14px;
      margin-top: 0 !important;
    }
  }

  .introduction.introductionPc {
    p {
      font-size: 14px;
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .introduction.introductionSp {
    @media screen and (min-width: 601px) {
      display: none;
    }
  }

  .interview {
    @media screen and (min-width: 601px) {
      padding-left: 32px;
      padding-right: 32px;
      padding-bottom: 120px;
      background-image: url(../images/content/treatment.png), url(../images/content/treatment.png), url(../images/content/treatment.png);
      background-position: -20px 200px, right -70px top 900px, left -20px bottom 0;
      background-size: 266px 323px;
    }

    @media screen and (max-width: 600px) {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 120px;
      background-image: url(../images/content/treatment.png), url(../images/content/treatment.png), url(../images/content/treatment.png);
      background-position: -8px 48px, right -8px top 1020px, left -8px bottom 0;
      background-size: 100px 122px;
    }

    .titleWrap {
      p {
        text-align: right;
        font-size: 12px;
        font-weight: bold;

        @media screen and (min-width: 601px) {
          font-size: 20px;
        }
      }
    }

    .titleWrap+.titleWrap {
      margin-top: 8px;

      .subTitle {
        margin-top: 0;
      }
    }

    img {
      display: block;
      width: 100%;
    }

    >section .img img {
      @media screen and (min-width: 601px) {
        max-width: 601px;
      }

      @media screen and (max-width: 600px) {
        width: calc(100% - 48px);
        margin: auto;
      }
    }

    .interviewDetail {
      @media screen and (min-width: 601px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media screen and (max-width: 600px) {
        margin-top: 32px;
      }
    }

    .interviewDetail .img {
      order: 2;
      margin-left: 32px;
    }

    .subTitle {
      @media screen and (min-width: 601px) {
        max-width: 620px;
      }

      @media screen and (max-width: 600px) {
        margin-top: 32px;
      }
    }

    .interviewDetail .subTitle {
      position: relative;

      @media screen and (min-width: 601px) {}

      @media screen and (max-width: 600px) {
        padding: 8px 12px;
      }

      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        border-image: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
        border-image-slice: 1;

        @media screen and (min-width: 601px) {
          width: 37px;
          height: 37px;
        }

        @media screen and (max-width: 600px) {
          width: 16px;
          height: 16px;
        }
      }

      &:before {
        border-top: 2px solid;
        border-left: 2px solid;

        @media screen and (min-width: 601px) {
          top: 0;
          left: -16px;
        }

        @media screen and (max-width: 600px) {
          top: 0;
          left: 0;
        }
      }

      &:after {
        border-bottom: 2px solid;
        border-right: 2px solid;

        @media screen and (min-width: 601px) {
          bottom: 0;
          right: 0;
        }

        @media screen and (max-width: 600px) {
          bottom: 0;
          right: 0;
        }
      }
    }

    .textBody {
      margin-top: 32px;

      @media screen and (min-width: 601px) {
        max-width: 580px;
      }
    }

    .interviewer {
      font-weight: 700;
    }

    .btn {
      width: 100%;

      @media screen and (min-width: 601px) {
        width: 229px;
        margin-top: 40px;
      }

      @media screen and (max-width: 600px) {
        margin-top: 48px;
        margin-top: 32px;
      }
    }

    .present {
      @media screen and (min-width: 601px) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 120px;
      }

      @media screen and (max-width: 600px) {
        margin-top: 48px;
      }

      .body {
        @media screen and (min-width: 601px) {
          margin-left: 32px;
        }
      }
    }
  }

  &.noroiNoRondo .interview {
    background-image: url(../images/content/noroi-no-rondo/treatment.png), url(../images/content/noroi-no-rondo/treatment.png), url(../images/content/noroi-no-rondo/treatment.png);
  }

  &.noroiNoRondo .interview .interviewDetail .subTitle {

    &:before,
    &:after {
      border-image: linear-gradient(to right, #1AD3A7 0%, #0EB1D4 100%);
      border-image-slice: 1;
    }
  }

  &.noroiNoRondo .product {
    background: linear-gradient(135deg, #1AD3A7 0%, #0EB1D4 100%);
  }

  &.hugTime .interview {
    background-image: url(../images/content/hug-time/treatment.png), url(../images/content/hug-time/treatment.png), url(../images/content/hug-time/treatment.png);
  }

  &.hugTime .interview .interviewDetail .subTitle {

    &:before,
    &:after {
      border-image: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
      border-image-slice: 1;
    }
  }

  &.hugTime .product {
    background: linear-gradient(135deg, #EF2CCE 0%, #FF927C 100%);
  }

  &.kouenNoMaken .interview {
    background: none;
  }

  &.kouenNoMaken .interview .interviewDetail .subTitle {

    &:before,
    &:after {
      border-image: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
      border-image-slice: 1;
    }
  }

  &.kouenNoMaken .product {
    background: linear-gradient(135deg, #FFBC80 0%, #FF8C8C 100%);

    &::after {
      display: none;
    }
  }

  &.reply .interview {
    background-image: url(../images/content/fuyuzora-melt-reply/treatment.png), url(../images/content/fuyuzora-melt-reply/treatment.png), url(../images/content/fuyuzora-melt-reply/treatment.png);
  }

  &.reply .interview .interviewDetail .subTitle {

    &:before,
    &:after {
      border-image: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
      border-image-slice: 1;
    }
  }

  &.reply .product {
    background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
  }

  &.kurodaSan .interview {
    background-image: url(../images/content/kuroda-san/treatment.png), url(../images/content/kuroda-san/treatment.png), url(../images/content/kuroda-san/treatment.png);
  }

  &.kurodaSan .interview .interviewDetail .subTitle {

    &:before,
    &:after {
      border-image: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
      border-image-slice: 1;
    }
  }

  &.kurodaSan .product {
    background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
  }

  &.oshiKatsu .interview {
    background-image: url(../images/content/oshi-katsu/treatment.png), url(../images/content/oshi-katsu/treatment.png), url(../images/content/oshi-katsu/treatment.png);
  }

  &.oshiKatsu .interview .interviewDetail .subTitle {

    &:before,
    &:after {
      border-image: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
      border-image-slice: 1;
    }
  }

  &.oshiKatsu .product {
    background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
  }

  &.melody .interview {
    background-image: url(../images/content/melody/treatment.png), url(../images/content/melody/treatment.png), url(../images/content/melody/treatment.png);
  }

  &.melody .interview .interviewDetail .subTitle {

    &:before,
    &:after {
      border-image: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
      border-image-slice: 1;
    }
  }

  &.melody .product {
    background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
  }

  .product {
    position: relative;
    z-index: 1;
    background: linear-gradient(135deg, #4D9AE4 0%, #BD41FF 100%);

    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #ffffffb3;
    }

    @media screen and (min-width: 801px) {
      padding: 0 0 0 32px;
    }

    @media screen and (max-width: 800px) {
      padding: 56px 24px;
    }

    .inner {
      @media screen and (min-width: 801px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1340px;
        margin-right: 0;
        margin-left: auto;
      }
    }

    .textBody {
      @media screen and (min-width: 801px) {
        padding: 24px 0;
      }
    }

    .logo {
      @media screen and (min-width: 801px) {
        max-width: 443px;
      }

      img {
        @media screen and (min-width: 801px) {
          width: 100%;
        }

        @media screen and (max-width: 800px) {
          width: 230px;
        }
      }
    }

    .subTitle {
      margin-top: 32px;
    }

    p {
      @media screen and (min-width: 801px) {
        margin-top: 16px;
      }

      @media screen and (max-width: 800px) {
        margin-top: 8px;
      }
    }

    .imgContentsSp {
      @media screen and (min-width: 961px) {
        display: none;
      }

      @media screen and (max-width: 960px) and (min-width: 801px) {
        display: none;
      }

      @media screen and (max-width: 800px) {
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-top: 32px;
      }
    }

    .imgContentsIpad {
      @media screen and (min-width: 961px) {
        display: none;
      }

      @media screen and (max-width: 960px) and (min-width: 801px) {
        max-width: 679px;
      }

      @media screen and (max-width: 800px) {
        display: none;
      }

      img {
        width: 100%;
      }
    }

    .imgContentsPc {
      @media screen and (min-width: 961px) {
        max-width: 679px;
      }

      @media screen and (max-width: 960px) and (min-width: 801px) {
        display: none;
      }

      @media screen and (max-width: 800px) {
        display: none;
      }
    }

    .imgContentsPc img {
      @media screen and (min-width: 801px) {
        width: 100%;
      }
    }

    .btn {
      @media screen and (min-width: 801px) {
        width: 56%;
        min-width: 240px;
        margin-top: 40px;
      }

      @media screen and (max-width: 800px) {
        width: 100%;
        margin-top: 32px;
      }
    }
  }

  .csp {
    text-align: center;
    padding: 0 16px 174px;

    @media screen and (min-width: 768px) {
      padding: 0 32px 200px;
    }

    p {
      font-size: 24px;
      font-weight: bold;

      @media screen and (min-width: 768px) {
        font-size: 40px;
      }
    }

    img {
      width: 100%;
      margin: auto;

      @media screen and (min-width: 768px) {
        max-width: 917px;
      }

      &.imgPc {
        display: none;

        @media screen and (min-width: 768px) {
          display: block;
          margin-top: 24px;
        }
      }

      &.imgSp {
        display: block;
        margin-top: 24px;

        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }
  }

  .recommendContent {
    text-align: center;
    padding: 0 16px 40px;

    @media screen and (min-width: 768px) {
      padding: 0 32px 64px;
    }

    p {
      font-size: 24px;
      font-weight: bold;

      @media screen and (min-width: 768px) {
        font-size: 40px;
      }
    }

    img {
      width: 100%;
      margin: auto;
      filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, .15));
      transition: opacity .2s;

      &:hover {
        opacity: .6;
      }

      @media screen and (min-width: 768px) {
        max-width: 917px;
      }

      &.imgPc {
        display: none;

        @media screen and (min-width: 768px) {
          display: block;
          margin-top: 24px;
        }
      }

      &.imgSp {
        display: block;
        margin-top: 24px;

        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }

    a {
      display: block;
      width: fit-content;
      margin: auto;
    }
  }

  .footer {
    padding: 48px 24px 32px;

    @media screen and (min-width: 600px) {
      margin: auto;
      padding: 60px 32px 80px;
    }

    .footerInner {
      position: relative;
      margin: auto;

      @media screen and (min-width: 600px) {
        width: 100%;
        max-width: 1240px;
      }
    }

    .subTitle {
      @media screen and (min-width: 601px) {
        display: none;
      }
    }

    .btns {
      @media screen and (min-width: 601px) {
        display: flex;
      }

      @media screen and (max-width: 600px) {
        margin-top: 28px;
      }

      li {
        text-align: center;

        p {
          font-weight: 500;

          @media screen and (min-width: 601px) {
            font-size: 16px;
          }

          @media screen and (max-width: 600px) {
            font-size: 12px;
            letter-spacing: 0.5em;
          }
        }
      }

      li+li {
        @media screen and (min-width: 601px) {
          margin-left: 60px;
        }

        @media screen and (max-width: 600px) {
          margin-top: 32px;
        }
      }

      .btn {
        margin-top: 4px;

        @media screen and (min-width: 601px) {
          width: 229px;
        }

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }

    .snsLinks {
      display: flex;
      margin-top: 56px;

      .btnTw {
        display: inline-block;
        height: 35px;
        line-height: 35px;
        padding: 0 12px;
        border-radius: 18px;
        background-color: #1D9BF0;
        color: #fff;
        font-size: 14px;
        text-decoration: none;
        transition: opacity .2s;

        &:before {
          content: "";
          display: inline-block;
          position: relative;
          top: 3px;
          width: 20px;
          height: 17px;
          margin-right: 2px;
          background-image: url("../images/x_white.svg");
          background-size: 100%;
        }

        &:hover {
          opacity: .6;
        }
      }

      .btnLine {
        display: inline-block;
        border-radius: 35px;
        overflow: hidden;
        transition: opacity .2s;
        margin-left: 8px;

        img {
          height: 35px;
        }

        &:hover {
          opacity: .6;
        }
      }

      .btnTw,
      .btnLine {
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }

    .inner {
      position: relative;

      @media screen and (min-width: 601px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 80px;
      }

      @media screen and (max-width: 600px) {
        margin-top: 0px;
      }
    }

    .backToTopWrap {
      @media screen and (min-width: 601px) {
        position: absolute;
        z-index: 1;
        top: -100px;
        right: -32px;
      }

      @media screen and (max-width: 600px) {
        text-align: right;
      }
    }

    .backToTop {
      width: 13px;
      margin-left: -48px;

      img {
        scale: 1;
        transition: scale .2s;

        @media screen and (min-width: 601px) {
          &:hover {
            scale: 1.2;
          }
        }
      }

      @media screen and (max-width: 600px) {
        top: -40px;
        right: auto;
        width: 14px;
      }
    }

    .linkList {
      margin-top: 24px;

      @media screen and (min-width: 600px) {
        margin-top: 0;
      }

      a {
        color: #7D7D7D;
        font-size: 12px;
        text-decoration: none;

        @media screen and (min-width: 601px) {
          &:hover {
            text-decoration: underline;
          }
        }
      }

      @media screen and (min-width: 600px) {
        display: flex;
        align-items: center;
      }

      li+li {
        margin-top: 8px;

        @media screen and (min-width: 600px) {
          margin-top: 0;
          margin-left: 24px;
        }
      }
    }

    .btns .btnTw {
      background-color: #000;
      border: none;
      color: #fff;
      transition: background-color .2s;

      &:after {
        content: "";
        display: inline-block;
        position: relative;
        top: 3px;
        width: 18px;
        height: 18.4px;
        margin-left: 8px;
        background-image: url("../images/x_white.svg");
        background-size: 100%;
      }

      &:hover {
        background-color: #4d4d4d;
      }
    }

    .copylight {
      margin-top: 24px;

      @media screen and (min-width: 768px) {
        margin-top: 0;
      }

      small {
        font-size: 8px;

        @media screen and (min-width: 768px) {
          font-size: 12px;
        }
      }

      img.vector {
        width: 66px;

        @media screen and (min-width: 768px) {
          width: 104px;
        }
      }

      img.pazle {
        width: 61px;

        @media screen and (min-width: 768px) {
          width: 85px;
        }
      }

      span {
        margin: 2px 4px 0 8px;
      }

      span,
      small {
        position: relative;
        top: 3px;
      }
    }
  }

  &.kouenNoMaken {

    h2,
    h3:not(.subTitle) {
      font-family: 'Simonetta', cursive;
    }

    .actor {
      background: linear-gradient(to bottom, #fff 0%, #fff 60%, #ffffff00 100%);
    }

    .story {
      padding-left: 0;
      padding-right: 0;
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: url("../images/content/kouen-no-maken/bg_story_sp.png");
      ;
      background-position: top 160px left 0px;

      @media screen and (min-width:425px) and (max-width:768px) {
        background-position: top 100px left 0px;
      }

      @media screen and (min-width:769px) and (max-width:1024px) {
        background-image: url("../images/content/kouen-no-maken/bg_story_pc.png");
        ;
        background-position: top 240px left 0px;
      }

      @media screen and (min-width: 1025px) {
        background-image: url("../images/content/kouen-no-maken/bg_story_pc.png");
        ;
        background-position: top 120px left 0px;
      }
    }

    .outline {
      padding-left: 24px;
      padding-right: 24px;

      @media screen and (min-width: 600px) {
        padding-left: 32px;
        padding-right: 32px;
        text-align: center;
      }

      p {
        line-height: 2.1;
        font-size: 18px;
        font-family: "Hiragino Mincho Pro";
        font-weight: 600;

        @media screen and (min-width: 600px) {
          font-size: 21px;
        }
      }
    }

    .episode {
      margin-top: 80px;
      padding-left: 24px;
      padding-right: 24px;

      @media screen and (min-width: 600px) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
        padding-left: 32px;
        padding-right: 32px;
      }

      .imgWrap {
        @media screen and (min-width: 600px) {
          max-width: 564px;
        }

        img {
          width: 100%;
        }
      }

      h3 {
        font-size: 32px;
        text-align: center;

        @media screen and (min-width: 600px) {
          font-size: 52px;
          text-align: left;
        }
      }

      p {
        font-size: 18px;
        line-height: 2.1;
        font-family: "Hiragino Mincho Pro";
        font-weight: 600;

        @media screen and (min-width: 600px) {
          font-size: 21px;
        }
      }

      .body {
        margin-top: 80px;

        @media screen and (min-width: 600px) {
          margin-top: 0;
        }
      }
    }

    .character {
      margin-top: 80px;
      padding-left: 24px;
      padding-right: 24px;
      background: linear-gradient(to bottom, #ffffff00 0%, rgba(255, 255, 255, .6) 25%, #fff 50%, rgba(255, 255, 255, .8) 80%, #ffffff00 100%);

      @media screen and (min-width: 600px) {
        margin-top: 160px;
        padding-left: 32px;
        padding-right: 32px;
      }

      .characterWrap {
        @media screen and (min-width: 600px) {
          display: flex;
          justify-content: center;
          max-width: 1240px;
          margin: auto;
        }
      }

      img {
        @media screen and (min-width:769px) and (max-width:1024px) {
          max-width: 461px;
          width: 32vw;
          margin-top: 120px;
        }

        @media screen and (min-width: 1025px) {
          max-width: 461px;
          width: 32vw;
          margin-top: 40px;
        }
      }

      .characterItem {
        position: relative;
        width: 100%;
      }

      .characterItem+.characterItem {
        @media screen and (min-width: 600px) {
          margin-left: 40px;
        }
      }

      .characterIntroduction {
        position: relative;
        padding: 0 16px 24px 24px;
        margin-top: 40px;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 60px;
          height: 49px;
          background: url("../images/content/kouen-no-maken/decoration.svg");
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 60px;
          height: 49px;
          background: url("../images/content/kouen-no-maken/decoration.svg");
          transform: rotate(180deg);
        }

        @media screen and (min-width: 769px) {
          position: absolute;
          top: 0;
          right: 0;
          margin-top: 0;
        }
      }

      .characterRole {
        font-size: 32px;
        font-family: "Hiragino Mincho Pro";
        font-weight: 600;

        span {
          font-size: 18px;
        }
      }

      .characterText {
        font-size: 14px;
        font-family: "Hiragino Mincho Pro";
        font-weight: 600;

        @media screen and (min-width: 769px) {
          max-width: 246px;
          width: 22vw;
          margin-top: 8px;
        }
      }

      .introduction {
        margin-top: 48px;
      }

      .btnWrap {
        margin-top: 56px;

        @media screen and (min-width: 600px) {
          margin-top: 100px;
        }
      }
    }

    .interview {

      .interviewDetail,
      .present {
        align-items: flex-start;

        .img {
          position: relative;
          z-index: 1;
        }

        .imgSign {
          position: absolute;
          bottom: -7vw;
          right: -10vw;
          width: 50vw;

          @media screen and (min-width: 600px) {
            bottom: -47px;
            right: -59px;
            width: 300px;
          }
        }
      }

      .present .img::after {
        background-image: url("../images/content/kouen-no-maken/decoration_white.svg"), url("../images/content/kouen-no-maken/decoration_white_bottom.svg");
      }
    }
  }

  .relatedContent {
    @media screen and (min-width: 601px) {
      padding: 0 32px;

      .relatedContentSP {
        display: none;
      }
    }

    @media screen and (max-width: 600px) {
      padding: 0 24px;

      .relatedContentPc {
        display: none;
      }
    }

    a {
      display: block;
      width: fit-content;

      @media screen and (min-width: 601px) {
        margin: 160px auto 0;
      }

      @media screen and (max-width: 600px) {
        margin: 80px auto 0;
      }
    }

    img {
      filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, .15));
      transition: opacity .2s;

      &:hover {
        opacity: .6;
      }
    }

    .relatedContentPc {
      width: 100%;
      max-width: 816px;
    }

    .relatedContentSP {
      width: 100%;
    }
  }
}