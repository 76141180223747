@import "./variables";

.Menu {
    padding: 80px 32px 88px !important;

    @media screen and (min-width: 600px) {

        .btn_primary,
        ul,
        .logout,
        .banner {
            max-width: 640px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .inner {
        max-width: 640px;
        margin: auto;
    }

    h1 {
        display: inline-block;
        margin-bottom: 24px;
        font-size: 24px;
        background: $color_contents_grad2;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media screen and (min-width: 600px) and (max-width: 768px) {

        .btn_primary,
        ul,
        .logout {
            max-width: 100%;
        }
    }

    ul:not(.snsList) {
        padding-top: 16px;
        padding-bottom: 16px;

        +ul {
            margin-top: 24px;
        }
    }

    li a {
        height: 44px;
        line-height: 44px;
    }

    ul:not(.snsList),
    .logout {
        border-radius: 10px;
        background-color: $color_background_primary;
    }

    ul:not(.snsList) li a,
    .logout {
        display: block;
        padding-left: 16px;
        padding-right: 16px;
        color: $color_contents_primary;
        text-decoration: none;
    }

    ul:not(.snsList) li {
        position: relative;

        &::after {
            content: "\e909";
            font-family: "pazle";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            color: $color_contents_tertiary;
            font-size: 16px;
        }
    }

    .snsList {
        display: flex;
        font-size: 12px;

        li {
            text-align: center;
        }

        img {
            width: 44px;
            height: 44px;
        }

        a {
            text-decoration: none;
        }

        .line {
            color: #4CC764;
        }

        .tw {
            color: #000;
        }

        .inst {
            color: #FF0079;
        }

        li+li {
            margin-left: 16px;
        }
    }

    .logout {
        width: 100%;
        height: 56px;
        line-height: 56px;
        margin-top: 24px;
        border: none;
        cursor: pointer;
        font-weight: 500;
    }

    .logout {
        color: $color_contents_negative;
        text-align: center;
    }

    .banner {
        display: block;
        width: 100%;
        margin-top: 24px;

        +ul {
            margin-top: 32px;
        }

    }

    .banner img {
        display: block;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 5px 25px rgba(0, 0, 0, .2);
    }

    .btn_primary {
        display: block;
        position: relative;
        width: 100%;
        height: 48px;
        line-height: 48px;
        border: none;
        border-radius: 25px;
        background: $color_background_grad border-box border-box;
        color: $color_contents_inverse;
        cursor: pointer;
        font-weight: 500;
        text-align: center;
        text-decoration: none;

        +ul {
            margin-top: 32px;
        }
    }
}

.Profile,
.Mail,
.Password {
    padding: 80px 32px 32px;

    @media screen and (min-width: 600px) {

        // padding-left: 272px;
        form {
            max-width: 640px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media screen and (min-width: 600px) and (max-width: 768px) {

        // padding-left: 124px;
        form {
            max-width: 100%;
        }
    }

    li+li {
        margin-top: 16px;
    }

    label {
        >* {
            margin-top: 8px;
        }
    }

    li>label {
        display: flex;
        flex-direction: column;
    }

    input[type=text],
    input[type=date],
    input[type=email],
    input[type=password],
    select {
        height: 48px;
        padding: 0 8px;
        border: none;
        border-radius: 10px;
        background-color: $color_background_primary;

        &:focus {
            outline: none;
            border: 2px solid $color_border_accent;
        }

        &.invalid {
            border: 2px solid $color_border_negative;
            background-color: $color_background_negative_pale;
        }

        &:-webkit-autofill {
            box-shadow: 0 0 0 1000px white inset;
        }
    }

    .btn_secondary {
        position: relative;
        display: block;
        height: 48px;
        line-height: 48px;
        margin-top: 16px;
        padding: 0 8px;
        border: none;
        border-radius: 9999px;
        background-color: $color_background_primary;
        color: $color_contents_primary;
        text-align: center;
        text-decoration: none;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid transparent;
            border-radius: 9999px;
            background: $color_background_grad border-box border-box;
            mask: linear-gradient($color_background_primary 0 0) padding-box, linear-gradient($color_background_primary 0 0) border-box;
            mask-composite: exclude;
        }
    }

    .error {
        margin-top: 8px;
        color: $color_contents_negative;
        font-weight: 500;
    }

    .btn_primary {
        display: block;
        width: 100%;
        height: 48px;
        line-height: 48px;
        margin-top: 32px;
        padding: 0;
        border: none;
        border-radius: 25px;
        background: $color_background_grad border-box border-box;
        color: $color_contents_inverse;
        cursor: pointer;
        font-weight: 500;
        text-align: center;
        text-decoration: none;
    }

    .supplement {
        font-size: 12px;
        color: $color_contents_secondary;
    }

    .snsList {}
}