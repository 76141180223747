@import "./variables";
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@800&display=swap');

.notfound {
  h1 {
    font-family: 'Rubik', sans-serif;
    text-align: center;
    background: $color_contents_grad;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 100px;
    font-weight: 800;
  }
  .text {
    position: relative;
    margin-top: 16px;
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -44%);
        width: 309px;
        height: 155px;
        background-image: url(../images/404_bg.svg);
        background-size: auto;
    }
  }
  p strong {
    background: $color_contents_grad2;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
    font-weight: bold;
  }
  p {
    text-align: center;
  }
  p + p {
    margin-top: 8px;
  }
  img {
    display: block;
    width: 190px;
    margin: 56px auto 0;
  }
  .btn {
    display: block;
    position: relative;
    margin: 32px auto 0;
    width: 230px;
    height: 50px;
    border: none;
    border-radius: 25px;
    background: $color_background_grad border-box border-box;
    color: $color_contents_inverse;
    cursor: pointer;
    font-weight: 500;
    &.disabled {
        background: $states_overlay_disabled_background;
        color: $states_overlay_disabled_oncolor;
    }
}
}