@import "./variables";

.nav {
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: 56px;
  background-color: rgba(255,255,255,.8);
  backdrop-filter: blur(10px);
  border-top: 1px solid #E6E6E6;  
  // @media screen and (min-width: 600px) {
  //   top: 48px;
  //   left: 0;
  //   width: 240px;
  //   height: 100vh;
  //   padding: 16px 0;
  //   border-top: 1px solid $color_border_secondary;
  // }
  // @media screen and (min-width: 600px) and (max-width: 768px) {
  //   width: 92px;
  // }

  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    // @media screen and (min-width: 600px) {
    //   display: block;
    // }
    // li + li {
    //   @media screen and (min-width: 600px) {
    //     margin-top: 8px;
    //   }
    // }
    i {
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    }
    i::before {
      font-family: 'pazle' !important;
      color: $color_contents_secondary;
      font-size: 28px;
    }

    i.home::before {
      content: "\e90f";
    }
    .active i.home::before {
      content: "\e900";
    }

    i.mycontents::before {
      content: "\e90e";
    }
    .active i.mycontents::before {
      content: "\e901";
    }
    i.menu::before {
      content: "\e90b";
    }
    .active i.menu::before {
      content: "\e902";
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      // @media screen and (min-width: 600px) {
      //   flex-direction: row;
      //   height: 48px;
      //   padding: 8px 32px;
      // }
      // @media screen and (min-width: 600px) and (max-width: 768px) {
      //   display: grid;
      //   justify-content: center;
      //   align-items: center;
      //   padding: 0;
      // }
    }
    span {
      margin-top: 2px;
      color: $color_contents_secondary;
      font-size: 10px;
      font-weight: 600;
      // @media screen and (min-width: 600px) {
      //   margin-left: 16px;
      //   font-size: 16px;
      // }
      // @media screen and (min-width: 600px) and (max-width: 768px) {
      //   display: none;
      // }
    }
    a.active span,
    a.active i::before {
      color: $color_contents_primary;
    }
  }
}