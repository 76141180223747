@import "./variables";

.btn {
  border: none;
  border-radius: 24px;
  background: $color_background_grad border-box border-box;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  transition: opacity .2s;
  // &:hover {
  //   opacity: .6;
  // }
  &.primary {
    color: $color_contents_inverse;
  }
  &.secondary {
    position: relative;
    background: none;
    color: #FFF;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid transparent;
      border-radius: 24px;
      background: linear-gradient(to right, #FF2E8E 0%, #FF9749 100%) border-box border-box;
      mask: linear-gradient(#FFF 0 0) padding-box, linear-gradient(#FFF 0 0) border-box;
      mask-composite: exclude;
    }
  }
  &.medium {
    min-width: 240px;
    height: 48px;
    line-height: 48px;
    padding: 0 32px;
  }
  &.small {
    height: 28px;
    line-height: 28px;
    padding: 0 8px;
    font-size: 12px;
  }
}