@import "./variables";

.Contents {
    .contents {
        padding: 77px 24px 56px;
        scroll-snap-type: y mandatory;
        scroll-snap-align: center;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100dvh;
    }
    
    .contents_item {
        scroll-snap-align: start;
    }

    .btn_primary, 
    .btn_secondary {
        display: inline-block;
        position: relative;
        border: none;
        cursor: pointer;
        font-weight: 500;
    }

    .btn_primary {
        width: calc(100% - 32px);
        padding-left: 24px;
        padding-right: 24px;
        height: 48px;
        border-radius: 25px;
        background: $color_background_grad border-box border-box;
        color: $color_contents_inverse;
        &:disabled {
            background: $states_overlay_disabled_background;
            color: $states_overlay_disabled_oncolor;
            cursor: default;
        }
    }

    .btn_secondary {
        width: 250px;
        height: 40px;
        background: none;
        color: $color_contents_inverse;
        border-radius: 20px;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid transparent;
            border-radius: 20px;
            background: $color_background_grad border-box border-box;
            mask: linear-gradient($color_background_primary 0 0) padding-box, linear-gradient($color_background_primary 0 0) border-box;
            mask-composite: exclude;
        }
        &:disabled {
            background: $states_overlay_disabled_background;
            color: $states_overlay_disabled_oncolor !important;
            cursor: default;
        }
        &:disabled::before {
            background: $states_overlay_disabled_background;
        }
    }
    
    .guide_first {
        display: grid;
        place-items: center;
        position: fixed;
        z-index: 200;
        top: 0;
        opacity: 1;
        visibility: visible;
        width: 100%;
        height: 100%;
        background-color: $color_background_overlay_dark;
        transition: opacity .2s, visibility .2s;
        &.hidden {
            opacity: 0;
            visibility: hidden;
        }
        img {
            display: block;
            position: relative;
            right: 20px;
            margin: auto;
        }
        .text {        
            display: inline-block;
            margin-top: 20px;
            color: $color_contents_inverse;
            line-height: 2;
            text-align: center;
            font-size: 12px;
            strong {
                background: $color_contents_grad2;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 20px;
                font-weight: bold;
            }
        }
    }    

    .contents_item {
        width: 100%;
        max-width: 640px;
        scroll-snap-align: center;
        min-height: 60dvh;
        margin-left: auto;
        margin-right: auto;
        border-radius: 16px;
        background-color: $color_background_primary;
        box-shadow: 0 0 60px rgba(21, 0, 70, .17);
        margin-top: 6dvh;
        margin-bottom: 6dvh;
        &.current {
            position: relative;
            &::before,
            &::after {
                position: absolute;
                font-family: 'pazle' !important;
                content: "\e909";
                color: $color_contents_inverse;
                font-size: 3vh;
            }
            &::before {
                top: -5.5vh;
                left: 50%;
                transform: translateX(-50%) rotate(-90deg);
            }
            &::after {
                bottom: -5.5vh;
                left: 50%;
                transform: translateX(-50%) rotate(90deg);
            }
            &:first-child {
                &::before {
                    display: none;
                }
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
        .thumbnail {
            position: relative;
            cursor: pointer;
            &::after {
                font-family: "pazle";
                content: "\e907";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: grid;
                justify-content: center;
                align-items: center;
                color: $color_contents_inverse;
                font-size: 64px;
                border-radius: 8px 8px 0 0;
                background-color: rgba(0, 0, 0, .2);
            }
        }
        .thumbnail img {
            width: 100%;
            border-radius: 8px 8px 0 0;
        }
        .new {
            position: absolute;
            z-index: 1;
            top: -8px;
            left: -8px;
            width: 40px;
            height: 40px;
            line-height: 38px;
            border: 1px solid #fff;
            border-radius: 20px;
            background: $color_contents_grad2;
            color: $color_contents_inverse;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
        }
        .contents_item_wrap {
            .item_text {
                padding: 16px;
            }
            .title {
                font-weight: 500;
                line-height: 1.6;
            }
            .time {
                margin-top: 4px;
                color: $color_contents_secondary;
                font-size: 10px;
                line-height: 1;
            }
            .btns {
                display: flex;   
                margin-top: 12px;
            }
            .btn_primary {
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 40px;
                margin-top: 0;
                display: flex;
                i {
                    font-size: 20px;
                    margin-right: 4px;
                    margin-left: -2px;
                }
                i::before {
                    color: $states_overlay_disabled_oncolor;
                    
                }
            }
            .btn_secondary {
                width: 100%;
                margin-top: 0;
                color: $color_contents_primary;
                padding: 0 16px;
            }
            .btn_primary {
                margin-left: 8px;
            }
            .highlight {
                margin: 16px 0 0;
                white-space: pre-wrap;
                font-size: 12px;
                line-height: 1.2;
            }
            .actors {
                display: flex;
                margin-top: 8px;
                img {
                    width: 34px;
                }
                .text {
                    margin-left: 4px;
                }
                .actor_role {
                    color: $color_contents_secondary;
                    font-size: 10px;
                    line-height: 1;
                }
                .actor_name {
                    margin: 4px 0 0;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1;
                    background: $color_contents_grad;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                li {
                    display: flex;
                    align-items: center;
                }
                li + li {
                    margin-left: 8px;
                }
            }
        }
    }
    
    .loading_video {
        display: grid;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color_background_overlay_dark;
        color: $color_contents_inverse;
    }
    .modal_introduction {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 200;
        display: grid;
        place-items: center;
        margin: 0;
        width: 100vw;
        height: 100vh;
        visibility: visible;
        opacity: 1;
        transition: opacity .2s, visibility .2s;
        text-align: center;
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100vw;
            height: 100vh;
            backdrop-filter: blur(10px);
            background-color: $color_background_overlay_dark_pale;
            transition: opacity .2s, visibility .2s;
        }
        .inner {
            position: relative;
            width: 520px;
            max-width: calc(100vw - 32px);
            max-height: calc(100dvh - 32px);
            padding: 32px;
            border-radius: 10px;
            background-color: $color_background_primary;
        }
        .btn_close_wrap {
            position: fixed;
            width: 520px;
            max-width: calc(100vw - 32px);
        }
        .btn_close {
            float: right;
            transform: translateX(100%);
            margin-top: -24px;
            margin-right: 72px;
            border: none;
            cursor: pointer;
            font-size: 24px;
        }
        img {
            width: 100%;
        }
        .introduction_supplement {
            position: absolute;
            top: -12%;
            left: 50%;
            transform: translateX(-50%);
            width: 80%;
            max-width: 520px;
        }
    }
}