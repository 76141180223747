$color_contents_primary: #0F0F0F;
$color_contents_secondary: #878787;
$color_contents_tertiary: #CDCDCD;
$color_contents_grad: linear-gradient(to right, #FF2E8E 0%, #FF9749 100%);
$color_contents_grad2: linear-gradient(to right, #00AFFF 0%, #00AFFF 10%, #9D76FF 60%, #FF53FF 80%, #FF53FF 100%);
$color_contents_grad2_to-bottom: linear-gradient(to bottom, #00AFFF 0%, #00AFFF 10%, #9D76FF 60%, #FF53FF 80%, #FF53FF 100%);
$color_contents_inverse: #FFF;
$color_contents_accent: #00AFFF;
$color_contents_negative: #FF2E8E;

$color_background_primary: #FFF;
$color_background_secondary: #F3F3F3;
$color_background_tertiary: #0F0F0F;
$color_background_grad: linear-gradient(to right, #FF2E8E 0%, #FF9749 100%);
$color_background_negative: #FF2E8E;
$color_background_negative_pale: #FFE4F0;
$color_background_warning: #FF9749;
$color_background_overlay_dark: rgba(0, 0, 0, .8);
$color_background_overlay_dark_pale: rgba(0, 0, 0, .4);
$color_background_overlay_dark_transparent: rgba(0, 0, 0, 0);
$color_background_overlay_light: rgba(255, 255, 255, .6);

$color_border: #CDCDCD;
$color_border_secondary: #2C2C2C;
$color_border_accent: #00AFFF;
$color_border_negative: #FF2E8E;

$states_overlay_disabled_background: #CDCDCD;
$states_overlay_disabled_oncolor: #878787;