html {
  scroll-behavior: smooth;
}

.Interview {
  font-family: 'Ubuntu', 'M PLUS Rounded 1c', sans-serif;
  background-color: #fff;
  letter-spacing: 0;
  p {
    color: #2e2e2e;
    font-weight: 400;
    line-height: 1.6;
    font-size: 14px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
    strong {
      font-weight: 700;
    }
  }
  .btnPrimary,
  .btnSecondary,
  .btnTertiary {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
  }
  .btnPrimary,
  .btnSecondary {
    display: flex;
    justify-content: center;
    &::after {
      font-family: 'pazle';
      content: "\e909";
      display: block;
      font-size: 16px;
      margin-left: 2px;
    }
  }
  .btnSecondary {
    color: transparent;
    &::after {
      position: relative;
      z-index: 1;
      background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    @media screen and (min-width: 768px) {
      &:hover::after {
        background: linear-gradient(to right, #fff 0%, #fff 100%);
        background-clip: text;
      }
    }
  }
  .btnPrimary {
    position: relative;
    color: #fff;
    box-shadow: -8px 7px 15px rgba(0, 0, 0, .15);
    background: linear-gradient(to right, #FF5C83 0%, #FF927C 100%);
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      background-color: #fff;
      opacity: 0;
      transition: opacity .2s;
    }
    @media screen and (min-width: 768px) {
      &:hover:before {
        opacity: .4;
      }
    }
  }
  .btnSecondary {
    position: relative;
    background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%) border-box border-box;
    box-shadow: -8px 7px 15px rgba(0, 0, 0, .15);
    span {
      position: relative;
      z-index: 1;
      background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: color .2s;
    }
    &:before {
      content: "";
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      background-color: #fff;
      border-radius: 9999px;
      transition: opacity .2s;
    }
    @media screen and (min-width: 768px) {
      &:hover span {
        -webkit-text-fill-color: inherit;
        color: #fff;
      }
      &:hover:before {
        opacity: 0;
      }
    }
  }
  .btnTertiary {
    border: 1px solid #7D7D7D;
    color: #2E2E2E;
    transition: color .2s, border .2s;
    @media screen and (min-width: 768px) {
      &:hover {
        border: 1px solid #9D76FF;
        color: #9D76FF;
      }
    }
  }
  &.noroiNoRondo {
    .btnPrimary {
      background: linear-gradient(to right, #FF5A44 0%, #FFA044 100%);
    }
    .btnSecondary {
      background: linear-gradient(to right, #14BBDF 0%, #1AD3A7 100%);
      &::after {
        background: linear-gradient(to right, #14BBDF 0%, #1AD3A7 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }
      span {
        background: linear-gradient(to right, #14BBDF 0%, #1AD3A7 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }
    .btnTertiary {
      @media screen and (min-width: 768px) {
        &:hover {
          border: 1px solid #14BBDF;
          color: #14BBDF;
        }
      }
    }
  }
  &.hugTime {
    .btnPrimary {
      background: linear-gradient(to right, #FF3395 0%, #FC7EFF 100%);
    }
    .btnSecondary {
      background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
      &::after {
        background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }
      span {
        background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }
    .btnTertiary {
      @media screen and (min-width: 768px) {
        &:hover {
          border: 1px solid #EF2CCE;
          color: #EF2CCE;
        }
      }
    }
  }
  &.kouenNoMaken {
    .btnPrimary {
      background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
    }
    .btnSecondary {
      background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
      &::after {
        background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }
      span {
        background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }
    .btnTertiary {
      @media screen and (min-width: 768px) {
        &:hover {
          border: 1px solid #FF7A00;
          color: #FF7A00;
        }
      }
    }
  }
  &.reply {
    .btnPrimary {
      background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
    }
    .btnSecondary {
      background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
      &::after {
        background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }
      span {
        background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }
    .btnTertiary {
      @media screen and (min-width: 768px) {
        &:hover {
          border: 1px solid #00A3FF;
          color: #00A3FF;
        }
      }
    }
  }
  &.kurodaSan {
    .btnPrimary {
      background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
    }
    .btnSecondary {
      background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
      &::after {
        background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }
      span {
        background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }
    .btnTertiary {
      @media screen and (min-width: 768px) {
        &:hover {
          border: 1px solid #886AFF;
          color: #886AFF;
        }
      }
    }
  }
  &.oshiKatsu {
    .btnPrimary {
      background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
    }
    .btnSecondary {
      background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
      &::after {
        background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }
      span {
        background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }
    .btnTertiary {
      @media screen and (min-width: 768px) {
        &:hover {
          border: 1px solid #FF6F9F;
          color: #FF6F9F;
        }
      }
    }
  }
  &.melody {
    .btnPrimary {
      background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
    }
    .btnSecondary {
      background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
      &::after {
        background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover::after {
          background: linear-gradient(to right, #fff 0%, #fff 100%);
          background-clip: text;
        }
      }
      span {
        background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      @media screen and (min-width: 768px) {
        &:hover span {
          -webkit-text-fill-color: inherit;
          color: #fff;
        }
      }
    }
    .btnTertiary {
      @media screen and (min-width: 768px) {
        &:hover {
          border: 1px solid #F2779C;
          color: #F2779C;
        }
      }
    }
  }
  
  .btnSmall {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    border-radius: 20px;
    font-size: 16px;
  }
  .btnMedium {
    padding: 0 16px;
    border-radius: 30px;
    height: 40px;
      line-height: 40px;
      font-size: 16px;
    @media screen and (min-width: 768px) {
      height: 60px;
      line-height: 60px;
      font-size: 20px;
    }
  }
  .btnLarge {
    padding: 0 16px;
    border-radius: 40px;
    height: 60px;
      line-height: 60px;
      font-size: 20px;
    @media screen and (min-width: 768px) {
      height: 80px;
      line-height: 80px;
      font-size: 24px;
    }
  }

  .brPc {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
  .brSp {
    display: block;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .loading {
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s, visibility .3s;
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100vw;
      height: 100vh;
      background: linear-gradient(135deg, #0075ab26 10%, #ff7eff41 40%, #FCF565 80%);
      opacity: .36;
    }
    img {
      width: 240px;
    }
    p {
      margin-top: 16px;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
    }
    .word {
      display: inline-block;
      opacity: 0;
      scale: 0;
      @for $i from 1 to 14 {
        &:nth-child(#{$i}) {
          animation: fadeInText .4s cubic-bezier(.47,1.64,.41,.8) 0.1 * $i + s forwards;
        }
      }
      @keyframes fadeInText {
        100% {
          opacity: 1;
          scale: 1;
        }
      }
    }
    .wordFirstLetter {
      margin-left: 4px;
    }
  }

  .header {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    background-color: #F9F9F9;
    padding: 8px 24px;
    @media screen and (min-width: 768px) {
      padding: 8px 32px;
    }
    .headerWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1240px;
      margin: auto;
    }
    img {
      display: block;
      width: 118px;
      @media screen and (min-width: 768px) {
        width: 132px;
      }
    }
    .pageToSns {
      display: block;
      height: 35px;
      line-height: 35px;
      padding: 0 16px;
      border-radius: 18px;
      background-color: #fff;
      color: #2e2e2e;
      font-size: 14px;
      text-decoration: none;
      box-shadow: -8px 7px 15px rgba(0, 0, 0, 0.15);
      transition: color .2s;
      @media screen and (min-width: 768px) {
        display: none;
      }
      i {
        position: relative;
        top: 2px;
        margin-right: 4px;
        font-size: 16px;
        background: linear-gradient(to right, #FF5C83 0%, #FF927C 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .headerBtns {
      display: none;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }
      .btnTw {
        display: none;
        height: 35px;
        line-height: 35px;
        padding: 0 10px;
        border-radius: 18px;
        background-color: #000;
        color: #fff;
        font-size: 14px;
        text-decoration: none;
        box-shadow: -8px 7px 15px rgba(0, 0, 0, 0.15);
        transition: background-color .2s;
        &:before {
          content: "";
          display: inline-block;
          position: relative;
          top: 4px;
          width: 18px;
          height: 18.4px;
          margin-right: 2px;
          background-image: url("../images/x_white.svg");
          background-size: 100%;
          transition: opacity .2s;
        }
        &:hover {
          background-color: #4d4d4d;
        }
        @media screen and (min-width: 768px) {
          display: inline-block;
        }
      }
      .btnLine {
        position: relative;
        display: none;
        margin-left: 16px;
        border-radius: 18px;
        box-shadow: -8px 7px 15px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        img {
          height: 35px;
          transition: opacity .2s;
          &:hover {
            opacity: .6;
          }
        }
        @media screen and (min-width: 768px) {
          display: inline-block;
        }
        &::before {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: #fff;
        }
      }
    }
    .btn {
      @media screen and (max-width: 767px) {
        position: fixed;
        bottom: 24px;
        right: 24px;
        display: grid;
        align-items: center;
        justify-content: center;
        width: 92px;
        height: 92px;
        padding: 0;
        border-radius: 9999px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.6;
        text-align: center;
        &::after {
          display: none;
        }
      }
      @media screen and (min-width: 768px) {
        margin-left: 16px;
      }
    }
  }
  &.noroiNoRondo header {
    .pageToSns i {
      background: linear-gradient(to right, #FF5A44 0%, #FFA044 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.hugTime header {
    .pageToSns i {
      background: linear-gradient(to right, #FF3395 0%, #FC7EFF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.kouenNoMaken header {
    .pageToSns i {
      background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.reply header {
    .pageToSns i {
      background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.kurodaSan header {
    .pageToSns i {
      background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.oshiKatsu header {
    .pageToSns i {
      background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.melody header {
    .pageToSns i {
      background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .main {
    background-size: 100%;
    background-attachment: fixed;
    padding-top: 103px;
    @media screen and (min-width: 768px) {
      padding-top: 120px;
    }
  }

  &.fuyuzoraMelt .main {
    background-image: url("../images/interview/fuyuzora-melt/bg_sp.png");
    @media screen and (min-width: 768px) {
      background-image: url("../images/interview/fuyuzora-melt/bg_pc.png");
    }
  }
  &.sayonaraNoMirai .main {
    background-image: url("../images/interview/sayonara-no-mirai/bg_sp.png");
    @media screen and (min-width: 768px) {
      background-image: url("../images/interview/sayonara-no-mirai/bg_pc.png");
    }
  }
  &.noroiNoRondo .main {
    background-image: url("../images/interview/noroi-no-rondo/bg_sp.png");
    @media screen and (min-width: 768px) {
      background-image: url("../images/interview/noroi-no-rondo/bg_pc.png");
    }
  }
  &.hugTime .main {
    background-image: url("../images/interview/hug-time/bg_sp.png");
    @media screen and (min-width: 768px) {
      background-image: url("../images/interview/hug-time/bg_pc.png");
    }
  }
  &.kouenNoMaken .main {
    background-image: url("../images/interview/kouen-no-maken/bg_sp.png");
    @media screen and (min-width: 768px) {
      background-image: url("../images/interview/kouen-no-maken/bg_pc.png");
    }
  }
  &.reply .main {
    background-image: url("../images/interview/fuyuzora-melt-reply/bg_sp.png");
    @media screen and (min-width: 768px) {
      background-image: url("../images/interview/fuyuzora-melt-reply/bg_pc.png");
    }
  }
  &.kurodaSan .main {
    background-image: url("../images/interview/kuroda-san/bg_sp.png");
    @media screen and (min-width: 768px) {
      background-image: url("../images/interview/kuroda-san/bg_pc.png");
    }
  }
  &.oshiKatsu .main {
    background-image: url("../images/interview/oshi-katsu/bg_sp.png");
    @media screen and (min-width: 768px) {
      background-image: url("../images/interview/oshi-katsu/bg_pc.png");
    }
  }
  &.melody .main {
    background-image: url("../images/interview/melody/bg_sp.png");
    @media screen and (min-width: 768px) {
      background-image: url("../images/interview/melody/bg_pc.png");
    }
  }

  .body {
    margin-bottom: 80px;
      padding: 0 24px;
    @media screen and (min-width: 768px) {
      max-width: 782px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 160px;
      padding: 0 32px;
    }
    h1 {
      font-size: 24px;
      @media screen and (min-width: 768px) {
        font-size: 42px;
      }
    }
    h1 + p {
      margin-top: 24px;
      @media screen and (min-width: 768px) {
        margin-top: 48px;
      }
    }
    p {
      margin-top: 16px;
        margin-bottom: 16px;
      @media screen and (min-width: 768px) {
        margin-top: 32px;
        margin-bottom: 32px;
      }
      &.interviewer {
        background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        margin-top: 24px;
        @media screen and (min-width: 768px) {
          margin-top: 48px;
        }
      }
    }
    img {
      width: 100%;
      border-radius: 16px;
      margin-top: 24px;
        margin-bottom: 24px;
      @media screen and (min-width: 768px) {
        margin-top: 48px;
        margin-bottom: 48px;
      }
    }
  }
  &.noroiNoRondo {
    .body p.interviewer {
      background: linear-gradient(to right, #1AD3A7 0%, #0EB1D4 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.hugTime {
    .body p.interviewer {
      background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.kouenNoMaken {
    .body p.interviewer {
      background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.reply {
    .body p.interviewer {
      background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.kurodaSan {
    .body p.interviewer {
      background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.oshiKatsu {
    .body p.interviewer {
      background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.melody {
    .body p.interviewer {
      background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .product {
    position: relative;
    z-index: 1;
    background: linear-gradient(135deg, #4D9AE4 0%, #BD41FF 100%);
    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #ffffffb3;
    }
    padding: 56px 24px;
    @media screen and (min-width: 801px) {
      padding: 0 0 0 32px;
    }
    .inner {
      @media screen and (min-width: 801px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1340px;
        margin-right: 0;
        margin-left: auto;
      }
    }
    .textBody {
      @media screen and (min-width: 801px) {
        padding: 24px 0;
      }
    }
    .logo {
      @media screen and (min-width: 801px) {
        max-width: 443px;
      }
      img {
        width: 230px;
        @media screen and (min-width: 801px) {
          width: 100%;
        }
      }
    }
    .subTitle {
      font-weight: 800;
      letter-spacing: 0.05em;
      font-size: 24px;
        span {
          font-size: 14px;
        }
      @media screen and (min-width: 768px) {
        font-size: 37px;
        span {
          font-size: 23px;
        }
      }
      strong {
        background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        @media screen and (min-width: 768px) {
          font-size: 60px;
        }
      }
      small {
        font-size: 14px;
        @media screen and (min-width: 768px) {
          font-size: 37px;
        }
      }
    }
    p {
      margin-top: 8px;
      @media screen and (min-width: 801px) {
        margin-top: 16px;
      }
    }
    .imgContentsSp {
      @media screen and (min-width: 961px) {
        display: none;
      }
      @media screen and (max-width: 960px) and (min-width: 801px) {
        display: none;
      }
      @media screen and (max-width: 800px) {
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-top: 32px;
      }
    }
    .imgContentsIpad {
      @media screen and (min-width: 961px) {
        display: none;
      }
      @media screen and (max-width: 960px) and (min-width: 801px) {
        max-width: 679px;
      }
      @media screen and (max-width: 800px) {
        display: none;
      }
      img {
        width: 100%;
      }
    }
    .imgContentsPc {
      @media screen and (min-width: 961px) {
        max-width: 679px;
      }
      @media screen and (max-width: 960px) and (min-width: 801px) {
        display: none;
      }
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    .imgContentsPc img {
      @media screen and (min-width: 801px) {
        width: 100%;
      }
    }
    .btn {
      @media screen and (min-width: 801px) {
        width: 56%;
        min-width: 240px;
        margin-top: 40px;
      }
      @media screen and (max-width: 800px) {
        width: 100%;
        margin-top: 32px;
      }
    }
  }
  &.noroiNoRondo {
    .product {
      background: linear-gradient(135deg, #1AD3A7 0%, #0EB1D4 100%);
    }
  }
  &.hugTime {
    .product {
      background: linear-gradient(135deg, #EF2CCE 0%, #FF927C 100%);
    }
  }
  &.kouenNoMaken {
    .product {
      background: linear-gradient(135deg, #FFBC80 0%, #FF8C8C 100%);
      &:after {
        display: none;
      }
    }
  }
  &.reply {
    .product {
      background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
      &:after {
        display: none;
      }
    }
  }
  &.kurodaSan {
    .product {
      background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
      &:after {
        display: none;
      }
    }
  }
  &.oshiKatsu {
    .product {
      background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
      &:after {
        display: none;
      }
    }
  }
  &.melody {
    .product {
      background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
      &:after {
        display: none;
      }
    }
  }

  .btnWrap {
    margin-top: 120px;
    text-align: center;
    p {
      margin: 0;
      font-size: 16px;
      background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .btn {
      width: 100%;
      max-width: 373px;
      margin: 8px auto 0;
    }
    .btn + .btn {
      margin-top: 40px;
    }
  }

  &.noroiNoRondo {
    .btnWrap p {
      background: linear-gradient(to right, #1AD3A7 0%, #0EB1D4 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.hugTime {
    .btnWrap p {
      background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.kouenNoMaken {
    .btnWrap p {
      background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.reply {
    .btnWrap p {
      background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.kurodaSan {
    .btnWrap p {
      background: linear-gradient(to right, #FFCE95 0%, #886AFF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.oshiKatsu {
    .btnWrap p {
      background: linear-gradient(to right, #FF6F9F 0%, #9F7FEF 50%, #4ABFFF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.melody {
    .btnWrap p {
      background: linear-gradient(to right, #F2779C 0%, #FDC692 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .footer {
    padding: 48px 24px 32px;
    @media screen and (min-width: 768px) {
      margin: auto;
      padding: 60px 32px 80px;
    }
    .footerInner {
      position: relative;
      margin: auto;
      @media screen and (min-width: 7680px) {
        width: 100%;
        max-width: 1240px;
      }
    }
    .subTitle {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
    .btns {
      margin-top: 28px;
      @media screen and (min-width: 768px) {
        display: flex;
        margin-top: 0;
      }
      li {
        text-align: center;
        p {
          font-weight: 500;
          font-size: 12px;
          @media screen and (max-width: 767px) {
            letter-spacing: 0.5em;
          }
          @media screen and (min-width: 768px) {
            font-size: 16px;
          }
        }
      }
      li + li {
        @media screen and (max-width: 767px) {
          margin-top: 32px;
        }
        @media screen and (min-width: 768px) {
          margin-left: 60px;
        }
      }
      .btn {
        margin-top: 4px;
        width: 100%;
        @media screen and (min-width: 768px) {
          width: 229px;
        }
      }
    }
    
    .inner {
      position: relative;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 80px;
      }
    }
    .backToTopWrap {
      @media screen and (max-width: 767px) {
        text-align: right;
      }
      @media screen and (min-width: 768px) {
        position: absolute;
        z-index: 1;
        top: -100px;
        right: -32px;
      }
    }
    .backToTop {
      width: 13px;
      margin-left: -48px;
      @media screen and (max-width: 767px) {
        top: -40px;
        right: auto;
        width: 14px;
      }
      img {
        scale: 1;
        transition: scale .2s;
        @media screen and (min-width: 768px) {
          &:hover {
            scale: 1.2;
          }
        }
      }
    }
    .linkList {
      margin-top: 24px;
      @media screen and (min-width: 768px) {
        margin-top: 0;
      }
      a {
        color: #7D7D7D;
        font-size: 12px;
        text-decoration: none;
        @media screen and (min-width: 768px) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }
      li + li {
        margin-top: 8px;
        @media screen and (min-width: 768px) {
          margin-top: 0;
          margin-left: 24px;
        }
      }
    }
    .btns .btnTw {
      background-color: #000;
      border: none;
      color: #fff;
      transition: background-color .2s;
      &:after {
        content: "";
        display: inline-block;
        position: relative;
        top: 3px;
        width: 18px;
        height: 18.4px;
        margin-left: 8px;
        background-image: url("../images/x_white.svg");
        background-size: 100%;
      }
      &:hover {
        background-color: #4d4d4d;
      }
    }
    .snsLinks {
      display: flex;
      margin-top: 56px;
      .btnTw {
        display: inline-block;
        height: 35px;
        line-height: 35px;
        padding: 0 12px;
        border-radius: 18px;
        background-color: #1D9BF0;
        color: #fff;
        font-size: 14px;
        text-decoration: none;
        transition: opacity .2s;
        &:before {
          content: "";
          display: inline-block;
          position: relative;
          top: 3px;
          width: 20px;
          height: 17px;
          margin-right: 2px;
          background-image: url("../images/x_white.svg");
          background-size: 100%;
        }
        &:hover {
          opacity: .6;
        }
      }
      .btnLine {
        display: inline-block;
        border-radius: 35px;
        overflow: hidden;
        transition: opacity .2s;
        margin-left: 8px;
        img {
          height: 35px;
        }
        &:hover {
          opacity: .6;
        }
      }
      .btnTw,
      .btnLine {
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }
    .copylight {
      margin-top: 24px;
      @media screen and (min-width: 768px) {
        margin-top: 0;
      }
      small {
        font-size: 8px;
        @media screen and (min-width: 768px) {
          font-size: 12px;
        }
      }
      img.vector {
        width: 66px;
        @media screen and (min-width: 768px) {
          width: 104px;
        }
      }
      img.pazle {
        width: 61px;
        @media screen and (min-width: 768px) {
          width: 85px;
        }
      }
      span {
        margin: 2px 4px 0 8px;
      }
      span, small {
        position: relative;
        top: 3px;
      }
    }
  }
  .introduction {
    padding: 32px 24px;
    box-shadow: 5px 5px 25px rgba(0,0,0,.1);
    border-radius: 16px;
    margin-top: 64px;
    background-color: #fff;
    p {
      font-size: 14px;
      margin: 0;
    }
  }
  .introduction + .introduction {
    margin-top: 24px;
  }
  .csp {
    text-align: center;
    padding: 0 16px 174px;
    @media screen and (min-width: 768px) {
      padding: 0 32px 200px;
    }
    p {
      font-size: 24px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        font-size: 40px;
      }
    }
    img {
      width: 100%;
      margin: auto;
      @media screen and (min-width: 768px) {
        max-width: 917px;
      }
      &.imgPc {
        display: none;
        @media screen and (min-width: 768px) {
          display: block;
        }
      }
      &.imgSp {
        display: block;
        margin-top: 24px;
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }
  }
  .present {
    img {
      margin: 0;
    }
    .title {
      line-height: 1;
      text-align: center;
      + * {
        margin-top: 40px;
      }
    }
    .titleEn {
      color: #2e2e2e;
      font-size: 40px;
      font-weight: 700;
      line-height: 1.4;
      @media screen and (min-width: 768px) {
        font-size: 70px;
      }
    }
    .titleJa {
      display: block;
      color: #7d7d7d;
      font-size: 12px;
      font-weight: 400;
      @media screen and (min-width: 768px) {
        font-size: 18px;
      }
    }
    .subTitle {
      font-weight: 800;
      letter-spacing: 0.05em;
      @media screen and (min-width: 768px) {
        font-size: 24px;
        span {
          font-size: 18px;
        }
      }
      @media screen and (max-width: 767px) {
        font-size: 20px;
        span {
          font-size: 14px;
        }
      }
      strong {
        background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        @media screen and (min-width: 768px) {
          font-size: 48px;
        }
      }
      small {
        font-size: 14px;
        @media screen and (min-width: 768px) {
          font-size: 24px;
        }
      }
    }
    .body {
      margin-top: 48px;
      @media screen and (min-width: 768px) {
        max-width: 1024px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
      }
    }
    .text {
      @media screen and (min-width: 768px) {
        margin-left: 32px;
      }
    }
  }
  &.hugTime {
    .subTitle strong {
      background: linear-gradient(to right, #FF3395 0%, #FC7EFF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.noroiNoRondo {
    .subTitle strong {
      background: linear-gradient(to right, #FF5A44 0%, #FFA044 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.kouenNoMaken {
    .subTitle strong {
      background: linear-gradient(to right, #FF7A00 0%, #CE0000 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &.reply {
    .subTitle strong {
      background: linear-gradient(to right, #7ADC9B 0%, #00A3FF 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .readmore {
    width: 100%;
    margin-top: 24px;
    padding: 24px;
    border: 2px solid #E6E6E6;
    border-radius: 16px;
    p {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      @media screen and (min-width: 768px) {
        font-size: 20px;
      }
    }
    a {
      margin-top: 16px;
      margin-left: auto;
      margin-right: auto;
      max-width: 373px;
      &::after {
        display: none;
      }
    }
  }
  p:has(+ .readmore) {
    background: linear-gradient(to bottom, #2e2e2e, transparent);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}