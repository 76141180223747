@import "./variables";

.header {

  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: rgba(255,255,255,.8);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid #E6E6E6;
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
  }
  .logo {
      height: 27px;
  }
  .btn_back {
    display: grid;
    place-items: center;
    position: absolute;
    width: 48px;
    height: 48px;
    left: 0;
    border: none;
    color: $color_contents_secondary;
    cursor: pointer;
    font-size: 22px;
  }
  .login {
    display: flex;
    position: absolute;
    right: 16px;
    background: linear-gradient(to right, #4D9AE4 0%, #BD41FF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    i {
      font-size: 20px;
    }
    p {
      font-size: 8px;
      font-weight: 500;
    }
  }
  button {
    position: absolute;
    right: 16px;
  }
  .tab {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    height: 28px;
    top: 1px;
    a {
      display: block;
      padding-bottom: 4px;
      color: $color_contents_secondary;
      text-decoration: none;
      border-bottom: 2px solid transparent;
      &.active {
        background: linear-gradient(to right, #EF2CCE 0%, #FF927C 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        border-bottom: 2px solid #FF2E8E;
        border-image: $color_contents_grad;
        border-image-slice: 1;
        font-weight: 600;
      }
    }
  }
  .tab_item + .tab_item {
    margin-left: 16px;
  }
}