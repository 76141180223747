@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+JP:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap');

@keyframes fuwafuwa {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

html {
  scroll-behavior: smooth;
}

.Top {
  font-family: 'Ubuntu', 'IBM Plex Sans JP', sans-serif;
  background-color: #fff;
  letter-spacing: 0;
  overflow: hidden;
  p {
    font-size: 14px;
    color: #2e2e2e;
    font-weight: 400;
    line-height: 1.6;
    @media screen and (min-width: 768px) {
      font-size: 18px;
    }
    strong {
      font-weight: 700;
    }
    + p {
      margin-top: 24px;
    }
  }
  .title {
    line-height: 1;
    text-align: center;
    + * {
      padding-top: 80px;
    }
  }
  .titleEn {
    color: #2e2e2e;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.4;
    @media screen and (min-width: 768px) {
      font-size: 60px;
    }
    .titleWord {
      display: inline-block;
      // opacity: 0;
      // scale: 0;
    }
    // &.active {
    //   .titleWord {
    //     @for $i from 1 to 15 {
    //       &:nth-child(#{$i}) {
    //         animation: fadeInText .8s cubic-bezier(.47,1.64,.41,.8) 0.1 * $i + s forwards;
    //       }
    //     }
    //     @keyframes fadeInText {
    //       100% {
    //         opacity: 1;
    //         scale: 1;
    //       }
    //     }
    //   }
    // }
  }
  .titleJa {
    display: block;
    color: #7d7d7d;
    font-size: 12px;
    font-weight: 400;
    @media screen and (min-width: 768px) {
      font-size: 18px;
    }
  }
  .subTitle {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.05em;
    // opacity: 0;
    // transform: translateY(20px);
    // transition: opacity .8s ease-in-out, transform .8s ease-in-out;
    @media screen and (min-width: 768px) {
      font-size: 34px;
    }
    // &.active {
    //   opacity: 1;
    //   transform: translateY(0);
    // }
    span {
      font-size: 32px;
      @media screen and (min-width: 768px) {
        font-size: 60px;
      }
    }
    + * {
      margin-top: 24px;
      @media screen and (min-width: 768px) {
        margin-top: 56px;
      }
    }
  }
  .btn {
    display: inline-block;
    border-radius: 9999px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    line-height: 1.6;
    box-shadow: -8px 7px 15px rgba(0, 0, 0, .15);
  }
  .btnPrimary {
    position: relative;
    background: linear-gradient(111.77deg, #FFED58 -4.78%, #FF53FF 57.68%, #00AFFF 105.25%);
    color: #fff;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 9999px;
      background-color: #fff;
      opacity: 0;
      visibility: hidden;
      transition: opacity .2s, visibility .2s;
    }
    &:hover::after {
      opacity: 0.4;
      visibility: visible;
    }
  }
  .btnSecondary {
    position: relative;
    background: linear-gradient(111.77deg, #FFED58 -4.78%, #FF53FF 57.68%, #00AFFF 105.25%);
    color: #2E2E2E;
    span {
      position: relative;
    }
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      border-radius: 9999px;
    }
    &::before {
      top: 3px;
      right: 0;
      bottom: 0;
      left: 3px;
      background-color: #fff;
      width: calc(100% - 6px);
      height: calc(100% - 6px);
    }
    &::after {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #fff;
      opacity: 0;
      visibility: hidden;
      transition: opacity .2s, visibility .2s;
    }
    &:hover::after {
      opacity: 0.4;
      visibility: visible;
    }
  }
  .btnLarge {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    @media screen and (min-width:768px) and (max-width:1024px) {
      width: 400px;
      height: 64px;
      line-height: 64px;
      font-size: 20px;
    }
    @media screen and (min-width:1024px) {
      width: 520px;
      height: 80px;
      line-height: 80px;
      font-size: 20px;
    }
  }
  .btnMedium {
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    @media screen and (min-width: 768px) {
      width: 358px;
      height: 60px;
      line-height: 60px;
      font-size: 20px;
    }
  }
  .btnSmall {
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
  }
  .btnDisabled {
    pointer-events: none;
    &.btnPrimary {
      background: #D3D3D3;
    }
    &.btnSecondary {
      background: #D3D3D3;
      color: #D3D3D3;
    }
  }
  .btnLink {
    display: flex;
    align-items: center;
    width: fit-content;
    background: linear-gradient(70deg,#FF2E8E 0%,#FF9749 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: opacity .2s;
    &::after {
      font-family: "pazle";
      content: "\e909";
      display: block;
      font-size: 16px;
      margin-left: 4px;
    }
    &:hover {
      opacity: 0.4;
    }
    &.btnDisabled {
      background: linear-gradient(70deg,#CDCDCD 0%, #CDCDCD 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .btnPc {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  .brPc {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
  .brSp {
    display: block;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .loading {
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s, visibility .3s;
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100vw;
      height: 100vh;
      background: linear-gradient(135deg, #0075ab26 10%, #ff7eff41 40%, #FCF565 80%);
      opacity: .36;
    }
    img {
      width: 240px;
    }
    p {
      margin-top: 16px;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
    }
    .word {
      display: inline-block;
      opacity: 0;
      scale: 0;
      @for $i from 1 to 14 {
        &:nth-child(#{$i}) {
          animation: fadeInText .4s cubic-bezier(.47,1.64,.41,.8) 0.1 * $i + s forwards;
        }
      }
      @keyframes fadeInText {
        100% {
          opacity: 1;
          scale: 1;
        }
      }
    }
    .wordFirstLetter {
      margin-left: 4px;
    }
  }

  .header {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    padding: 16px 16px 0;
    @media screen and (min-width: 768px) {
      padding: 0 32px 0;
    }
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (min-width: 768px) {
        width: 100%;
        max-width: 1340px;
        margin: auto;
        padding-top: 2.8vw;
      }
    }
    .pageToSns {
      display: block;
      height: 35px;
      line-height: 35px;
      padding: 0 16px;
      border-radius: 18px;
      background-color: #fff;
      color: #2e2e2e;
      font-size: 14px;
      text-decoration: none;
      box-shadow: -8px 7px 15px rgba(0, 0, 0, 0.15);
      transition: color .2s;
      @media screen and (min-width: 768px) {
        display: none;
      }
      i {
        position: relative;
        top: 2px;
        margin-right: 4px;
        font-size: 16px;
        background: linear-gradient(111.77deg, #FFED58 -4.78%, #FF53FF 57.68%, #00AFFF 105.25%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .headerBtns {
      display: none;
      @media screen and (min-width: 768px) {
        display: flex;
      }
    }
    .navBtnPc {
      display: none;
      width: 230px;
      height: 35px;
      line-height: 35px;
      margin-left: 16px;
      border-radius: 18px;
      font-size: 16px;
      text-align: center;
      @media screen and (min-width: 768px) {
        display: block;
      }
    }
    .btnTw {
      display: none;
      height: 35px;
      line-height: 35px;
      padding: 0 10px;
      border-radius: 18px;
      background-color: #000;
      color: #fff;
      font-size: 14px;
      text-decoration: none;
      box-shadow: -8px 7px 15px rgba(0, 0, 0, 0.15);
      transition: background-color .2s;
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        top: 4px;
        width: 18px;
        height: 18.4px;
        margin-right: 2px;
        background-image: url("../images/x_white.svg");
        background-size: 100%;
        transition: opacity .2s;
      }
      &:hover {
        background-color: #4d4d4d;
      }
      @media screen and (min-width: 768px) {
        display: inline-block;
      }
    }
    .btnLine {
      position: relative;
      display: none;
      margin-left: 16px;
      border-radius: 18px;
      border: 1px solid #fff;
      box-shadow: -8px 7px 15px rgba(0, 0, 0, 0.15);
      overflow: hidden;
      img {
        height: 35px;
        transition: opacity .2s;
        &:hover {
          opacity: .6;
        }
      }
      @media screen and (min-width: 768px) {
        display: inline-block;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
      }
    }
    .logo {
      height: 37px;
      @media screen and (min-width:768px) and (max-width:1024px) {
        height: 48px;
      }
      @media screen and (min-width:1024px) {
        height: 48px;
      }
    }
  }

  .textBody {
    // opacity: 0;
    // transform: translateY(20px);
    // transition: opacity .8s ease-in-out, transform .8s ease-in-out ;
    // &.active {
    //   opacity: 1;
    //   transform: translateY(0);
    // }
  }

  .mainvisual {
    position: relative;
    overflow: hidden;
    .mainvisualImg {
      width: 100%;
    }
    .ambassadorPc,
    .text {
      z-index: 1;
    }
    .text {
      position: absolute;
      top: 35vw;
      padding: 0 16px;
      max-width: 1404px;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (min-width:768px) and (max-width:1025px) {
        top: 16vw;
        padding: 0 32px;
      }
      @media screen and (min-width: 1025px) {
        top: 10vw;
        padding: 0 32px;
      }
    }
    .copy {
      margin-bottom: 8px;
      font-size: 36px;
      font-weight: 600;
      line-height: 1.3;
      @media screen and (min-width:768px) and (max-width:1024px) {
        font-size: 40px;
      }
      @media screen and (min-width: 1024px) {
        font-size: 63px;
      }
    }
    .tag {
      display: inline;
      background-color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.9;
      letter-spacing: 0.08em;
      br {
        @media screen and (min-width:768px) and (max-width:1024px) {
          display: block;
        }
      }
    }
    .btnWrapperPc {
      display: none;
      @media screen and (min-width:768px) and (max-width:1024px) {
        display: block;
        position: relative;
        margin-top: 24px;
        width: auto;
      }
      @media screen and (min-width:1024px) {
        display: block;
        position: relative;
        margin-top: 48px;
        width: auto;
      }
    }
    .productImgPcWrap {
      display: none;
      @media screen and (min-width:768px) and (max-width:1025px) {
        display: none;
      }
      @media screen and (min-width: 1025px) {
        display: block;
        width: 520px;
      }
    }
    .productImgPc {
      display: block;
      width: 27vw;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }
    .mainvisualImgSp {
      display: block;
      @media screen and (min-width:768px) {
        display: none;
      }
    }
    .mainvisualImgPc {
      display: none;
      @media screen and (min-width:768px) {
        display: block;
      }
    }
    .ambassadorPc {
      display: none;
      @media screen and (min-width:768px) and (max-width:1024px) {
        display: block;
        height: 240px;
      }
      @media screen and (min-width:1024px) {
        display: block;
        height: 346px;
      }
    }
    .cspSp {
      position: absolute;
      bottom: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0 16px;
      @media screen and (min-width:768px) {
        display: none;
      }
      img {
        width: 86px;
      }
      p {
        font-size: 16px;
        color: #fff;
        line-height: 1.3;
        margin-left: 16px;
      }
    }
  }

  .btnWrapperSp {
    position: fixed;
    z-index: 100;
    bottom: 16px;
    width: calc(100% - 32px);
    margin: 0 16px;
    @media screen and (min-width:768px) {
      display: none;
    }
  }

  .bannerApp {
    width: 100%;
    margin: 64px auto 0;
    padding: 0 16px;
    @media screen and (min-width:768px) {
      max-width: 1024px;
      margin: 80px auto 0;
      padding: 0 32px;
    }
    img {
      width: 100%;
      border-radius: 20px;
      box-shadow: 10px 10px 30px rgba(0,0,0,.15);
      transition: opacity .2s;
      &:hover {
        opacity: .6;
      }
    }
    .bannerAppImgPc {
      display: none;
    }
    .bannerAppImgSp {
      display: block;
    }
    @media screen and (min-width:768px) {
      .bannerAppImgPc {
        display: block;
      }
      .bannerAppImgSp {
        display: none;
      }
    }
  }

  .publicContents {
    padding-top: 80px;
    @media screen and (min-width:768px) {
      padding-top: 160px;
    }
    .publicContentsTitle {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      span {
        font-size: 10px;
        font-weight: 500;
        @media screen and (min-width:768px) {
          font-size: 18px;
        }
      }
      h2 {
        display: inline-block;
        margin-top: 4px;
        font-weight: 700;
        letter-spacing: 0.1em;
        text-align: center;
        background: linear-gradient(70deg,#ffed58 20%,#ff53ff 50%,#00afff 80%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 21px;
        @media screen and (min-width:768px) {
          margin-top: 8px;
          font-size: 28px;
        }
      }
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: 52px;
        background: linear-gradient(to bottom, #00AFFF 0%, #00AFFF 10%, #9D76FF 60%, #FF53FF 80%, #FF53FF 100%);
        @media screen and (min-width:768px) {
          height: 107px;
        }
      }
      &:before {
        left: -16px;
        transform: rotate(-30deg);
      }
      &:after {
        right: -16px;
        transform: rotate(30deg);
      }
    }
    .contentsList {
      display: flex;
      align-items: flex-start;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      width: 100%;
      margin-top: -16px;
      margin-bottom: -16px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 48px;
      @media screen and (min-width:768px) {
        max-width: 1239px;
        margin-top: -32px;
        margin-bottom: -32px;
        padding-top: 64px;
      }
      > li {
        display: grid;
        align-items: center;
        justify-items: center;
        position: relative;
        margin: 16px;
        text-align: center;
        @media screen and (min-width: 768px) {
          margin: 32px;
        }
        &:not(.active) {
          p {
            color: #878787 !important;
          }
          .comingsoon {
            p {
              color: #fff !important;
            }
          }
        }
      }
      .imgContent {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 9999px;
        @media screen and (min-width: 768px) {
          width: 246px;
          height: 246px;
        }
      }
      .cvList {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        margin-top: -24vw;
        @media screen and (min-width: 768px) {
          margin-top: clamp(-104px, -5vw, -5vw);
        }
      }
      .cvList li {
        width: 25vw;
        @media screen and (min-width: 768px) {
          width: 10vw;
          max-width: 100px;
        }
        + li {
          margin-left: 24px;
          @media screen and (min-width: 768px) {
            margin-left: clamp(8px, 2.2vw, 32px);
          }
        }
        img {
          width: 100%;
        }
        .nameJa {
          margin-top: 4px;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.4;
        }
        .nameEn {
          margin-top: 4px;
          font-size: 10px;
          line-height: 1.4;
          @media screen and (min-width: 768px) {
            font-size: 12px;
          }
        }
        p + p {
          margin-top: -2px;
        }
      }
      .story {
        max-width: 246px;
        margin-top: 24px;
        text-align: center;
        @media screen and (min-width: 768px) {
          margin-top: 32px;
        }
        p {
          font-size: 12px;
          &:not(:first-child) {
            text-align: left;
          }
        }
        p + p {
          margin-top: 16px;
        }
      }
      .btns {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 24px;
        @media screen and (min-width: 768px) {
          margin-top: 32px;
        }
        .btnLink {
          margin: 24px auto 0;
        }
      }
      .comingsoon {
        position: absolute;
        top: 0vw;
        z-index: 2;
        display: grid;
        align-items: center;
        width: 100%;
        aspect-ratio: 1 / 1;
        p {
          font-size: 24px;
          font-weight: 500;
          line-height: 1.2;
          color: #fff;
        }
      }
    }
  }

  .interview {
    padding-top: 120px;
    @media screen and (min-width: 768px) {
      padding-top: 200px;
    }
    .list {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      margin-top: -16px;
      margin-bottom: -16px;
      margin-left: auto;
      margin-right: auto;
      @media screen and (min-width: 768px) {
        max-width: 1218px;
        margin-top: -24px;
        margin-bottom: -24px;
        padding-left: 12px;
        padding-right: 12px;
      }
      li {
        display: grid;
        align-items: center;
        justify-items: center;
        position: relative;
        text-align: center;
        margin: 16px;
        @media screen and (min-width: 768px) {
          margin: 24px;
        }
      }
      li a {
        scale: 1;
        transition: scale .2s;
      }
      li a:hover {
        scale: 1.1
      }
      li:not(.active) {
        display: none;
        @media screen and (min-width: 768px) {
          display: block;
        }
      }
      img {
        display: block;
        width: 100%;
        border-radius: 8px;
        @media screen and (min-width: 768px) {
          width: 350px;
        }
      }
    }
  }

  .beta {
    position: relative;
    margin-top: 120px;
    padding-top: 120px;
    background: 
      linear-gradient(to bottom, #ffffff00 0%, #fff 100%),
      linear-gradient(300deg, #FFF75833 0%, #FF53FF33 50%, #00AFFF33 100%);
    @media screen and (min-width:768px) and (max-width:1024px) {
      margin-top: 200;
      padding-top: 200px;
    }
    @media screen and (min-width: 1024px) {
      margin-top: 0;
      padding-top: 200px;
      background: none;
    }
    > * {
      position: relative;
      z-index: 2;
    }
    &::before {
      @media screen and (min-width: 1024px) {
        content: "";
        display: block;
        position: absolute;
        top: 380px;
        left: 250px;
        width: 120vw;
        height: 768px;
        border-radius: 384px;
        background: linear-gradient(135deg, rgba(255,255,255,.8) 0%, rgba(255,255,255,.8) 100%), linear-gradient(135deg, #FFF758 30%, #FF53FF 50%, #00AFFF 100%);
        opacity: .6;
        transform: rotate(-12deg);
      }
    }
    .inner {
      @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1144px;
        margin-left: auto;
        margin-right: auto;
      }
      .subTitle {
        margin-top: 40px;
        @media screen and (min-width:768px) and (max-width:1024px) {
          br {
            display: none;
          }
        }
        @media screen and (min-width: 1024px) {
          margin-top: 0;
        }
      }
      .text {
        @media screen and (min-width: 1024px) {
          width: 100%;
          max-width: 542px;
        }
      }
      .text,
      .image {
        margin-left: 16px;
        margin-right: 16px;
        @media screen and (min-width: 768px) {
          margin-left: 32px;
          margin-right: 32px;
        }
      }
      .image {
        margin: auto;
        padding-left: 16px;
        padding-right: 16px;
        filter: drop-shadow(-24px 28px 52px rgba(43, 43, 43, .25));
        @media screen and (min-width:768px) and (max-width:1024px) {
          padding-left: 32px;
          padding-right: 32px;
        }
        @media screen and (min-width: 1024px) {
          padding-left: 32px;
          padding-right: 0;
        }
        img {
          width: 100%;
        }
      }
      .btn {
        width: 100%;
        @media screen and (min-width: 768px) {
          margin-top: 80px;
        }
      }
    }
    .inner:last-of-type {
      .image {
        order: 2;
        padding-left: 16px;
        padding-right: 16px;
        @media screen and (min-width:768px) and (max-width:1024px) {
          padding-left: 32px;
          padding-right: 32px;
        }
        @media screen and (min-width: 1024px) {
          padding-left: 0;
          padding-right: 32px;
        }
      }
    }
    .inner + .inner {
      margin-top: 56px;
    }
  }

  .official {
    position: relative;
    margin-top: 96px;
    padding-top: 120px;
    padding-bottom: 120px;
    background: 
      linear-gradient(135deg, #ffffff0d 0%, #ffffff0d 100%),
      linear-gradient(135deg, #0000000d 0%, #0000000d 100%),
      linear-gradient(135deg, #E94D85 0%, #FF817D 50%, #ED9D53 100%);
    @media screen and (min-width: 768px) {
      margin-top: 240px;
      padding-top: 200px;
      padding-bottom: 200px;
    }
    > * {
      position: relative;
      z-index: 1;
    }
    .title {
      .titleEn,
      .titleJa,
      .tag {
        color: #fff;
      }
      .titleEn {
        margin-top: 8px;
      }
      .tag {
        font-size: 12px;
        font-weight: 500;
        @media screen and (min-width: 768px) {
          font-size: 24px;
        }
      }
    }
    .subTitle,
    p {
      color: #fff;
    }
    .inner {
      padding-top: 100px;
      @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 1180px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .subTitle {
      margin-top: 40px;
      @media screen and (min-width: 1024px) {
        margin-top: 0;
      }
    }
    .text,
    .image {
      margin-left: 16px;
      margin-right: 16px;
      @media screen and (min-width: 768px) {
        margin-left: 32px;
        margin-right: auto;
      }
    }
    .text {
      @media screen and (min-width: 1024px) {
        width: 100%;
        max-width: 542px;
        margin-top: 80px;
      }
    }
    .can {
      margin-top: 64px;
      @media screen and (min-width: 768px) {
        margin-top: 72px
      }
      .canTitle {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        @media screen and (min-width: 768px) {
          font-size: 18px;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 8px;
        @media screen and (min-width: 768px) {
          margin-top: 24px
        }
      }
      li {
        display: grid;
        align-items: center;
        justify-content: center;
        width: 183px;
        height: 183px;
        background-color: #fff;
        border-radius: 9999px;
        text-align: center;
        margin: 8px;
        @media screen and (min-width: 768px) {
          margin: 12px;
        }
        p {
          background: linear-gradient(to right, #00AFFF 0%, #00AFFF 10%, #9D76FF 60%, #FF53FF 80%, #FF53FF 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          font-size: 16px;
          font-weight: bold;
          @media screen and (min-width: 768px) {
            font-size: 18px;
          }
        }
      }
    }
    .image {
      order: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;
      left: -20px;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      // opacity: 0;
      // transition: opacity .8s ease-in-out;
      // &.active {
      //   opacity: 1;
      // }
      @media screen and (min-width: 1024px) {
        left: 0;
        margin-left: 0;
        margin-right: 32px;
      }
    }
    .image img {
      display: block;
    }
    .creator {
      position: relative;
      top: 20px;
      width: 332px;
      animation: 4s fuwafuwa infinite ease-in-out;
      @media screen and (min-width: 768px) {
        top: 30px;
        width: 574px;
      }
    }
    .arrow {
      position: absolute;
      top: 240px;
      left: 170px;
      width: 100px;
      @media screen and (min-width: 768px) {
        width: 171px;
        top: 410px;
        left: 295px;
      }
    }
    .consumer {
      width: 117px;
      animation: fuwafuwa 4s infinite .8s ease-in-out ;
      @media screen and (min-width: 768px) {
        width: 202px;
      }
    }
    .textCollaboration {
      position: absolute;
      width: 235px;
      transform: rotate(24deg);
      @media screen and (min-width:768px) and (max-width:1024px) {
        width: 407px;
      }
      @media screen and (min-width: 1024px) {
        width: 407px;
        right: 0;
      }
    }
    .movie {
      position: relative;
      display: block;
      width: 100%;
      max-width: 662px;
      margin-top: 64px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 16px;
      @media screen and (min-width: 768px) {
        margin-top: 200px;
        padding: 0 32px;
      }
      video {
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
      }
      img {
        position: absolute;
        bottom: -100px;
        left: 20px;
        width: 197px;
        @media screen and (min-width:768px) and (max-width:1024px) {
          width: 200px;
          bottom: -80px;
          left: -40px;
        }
        @media screen and (min-width: 1024px) {
          width: 267px;
          bottom: -80px;
          left: -80px;
        }
      }
    }
    .btnWrapper {
      margin-top: 130px;
      margin-left: 16px;
      margin-right: 16px;
      @media screen and (min-width: 768px) {
        margin-top: 56px;
        margin-left: auto;
        margin-right: auto;
      }
      .btn {
        display: block;
        margin: auto;
      }
      .btnLink {
        margin: 24px auto 0;
        background: linear-gradient(135deg, #fff 0%, #fff 100%);
        -webkit-background-clip: text;
        background-clip: text;
        @media screen and (min-width: 768px) {
          margin: 40px auto 0;
        }
      }
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 460px;
      right: 20px;
      width: 120vw;
      height: 149px;
      border-radius: 75px;
      background: #fff;
      opacity: .1;
      transform: rotate(-12deg);
      @media screen and (min-width:768px) and (max-width:1024px) {
        top: 680px;
        right: 100px;
        width: 140vw;
        height: 320px;
        border-radius: 160px;
      }
      @media screen and (min-width: 1024px) {
        width: 120vw;
        height: 768px;
        top: 480px;
        right: 250px;
        border-radius: 384px;
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 220px;
      left: 20px;
      width: 120vw;
      height: 149px;
      border-radius: 75px;
      background: #fff;
      opacity: .1;
      transform: rotate(-12deg);
      @media screen and (min-width:768px) and (max-width:1024px) {
        bottom: 320px;
        left: 100px;
        width: 140vw;
        height: 320px;
        border-radius: 160px;
      }
      @media screen and (min-width: 1024px) {
        width: 120vw;
        height: 768px;
        bottom: 280px;
        left: 250px;
        border-radius: 384px;
      }
    }
  }

  .ambassador {
    margin-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      p + p {
        margin: 0;
      }
      .text {
        position: relative;
        margin-left: -22px;
      }
      .name {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.04em;
      }
      .job {
        font-size: 12px;
      }
      img {
        width: 196px;
        filter: drop-shadow(0px 4px 10px rgba(43, 43, 43, .25));
      }
    }
    li:last-child {
      margin-top: -64px;
      padding-left: 32px;
      .text {
        order: -1;
        margin-left: 0x;
      }
      img {
        margin-left: -32px;
      }
    }
    .ambassadorMessage {
      display: block;
      width: 250px;
      margin: 16px auto 0;
    }
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .csp {
    text-align: center;
    margin-top: 96px;
    padding: 0 16px;
    @media screen and (min-width: 768px) {
      margin-top: 240px;
      padding: 0 32px;
    }
    p {
      font-size: 24px;
      font-weight: bold;
      @media screen and (min-width: 768px) {
        font-size: 40px;
      }
    }
    img {
      width: 100%;
      margin: auto;
      @media screen and (min-width: 768px) {
        max-width: 917px;
      }
      &.imgPc {
        display: none;
        @media screen and (min-width: 768px) {
          display: block;
        }
      }
      &.imgSp {
        display: block;
        margin-top: 24px;
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    }
  }

  .contact {
    background: linear-gradient(135deg, rgba(255, 247, 88, .2) 0%, rgba(255, 83, 255, .2) 55%, rgba(0, 175, 255, .2) 100%);
    .inner {
      padding: 48px 16px;
      @media screen and (min-width:768px) and (max-width:1024px) {
        margin-left: auto;
        margin-right: auto;
        padding: 132px 32px;
      }
      @media screen and (min-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1240px;
        margin-left: auto;
        margin-right: auto;
        padding: 132px 32px;
      }
    }
    .contactTitle {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.4;
      @media screen and (min-width: 768px) {
        font-size: 36px;
      }
    }
    .contactTextWrap {
      @media screen and (min-width: 1024px) {
        max-width: 578px;
        flex-shrink: 2;
      }
    }
    .contactText {
      margin-top: 32px;
      @media screen and (min-width: 768px) {
        line-height: 1.73;
      }
    }
    .btn {
      display: block;
      margin-top: 32px;
      text-align: center;
      background-color: #000;
      color: #fff;
      transition: background-color .2s;
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        top: 16px;
        width: 28px;
        height: 34px;
        margin-right: 8px;
        background-image: url("../images/x_white.svg");
        background-size: 100%;
        @media screen and (min-width: 768px) {
          top: 12px;
          width: 32px;
        }
      }
      &:hover {
        background-color: #4d4d4d;
      }
      @media screen and (min-width:768px) and (max-width:1024px) {
        margin-top: 32px;
        margin-left: auto;
        margin-right: auto;
      }
      @media screen and (min-width: 1024px) {
        margin-left: 32px;
      }
    }
  }

  .footer {
    padding: 48px 16px 100px;
    @media screen and (min-width: 768px) {
      margin: auto;
      padding: 115px 32px 80px;
    }
    .footerInner {
      position: relative;
      margin: auto;
      @media screen and (min-width: 768px) {
        width: 100%;
        max-width: 1340px;
      }
    }
    .backToTopWrap {
      @media screen and (min-width: 768px) {
        text-align: right;
      }
    }
    .backToTop {
      position: absolute;
      top: 12px;
      right: 16px;
      width: 13px;
      margin-left: -48px;
      padding: 0 16px;
      img {
        scale: 1;
        transition: scale .2s;
        &:hover {
          scale: 1.2;
        }
      }
      @media screen and (min-width: 768px) {
        top: -147px;
        right: auto;
        width: 14px;
      }
    }
    
    .inner {
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .snsLinks {
      display: flex;
    }
    .btnTw {
      display: inline-block;
      height: 35px;
      line-height: 35px;
      padding: 0 12px;
      border-radius: 18px;
      background-color: #1D9BF0;
      color: #fff;
      font-size: 14px;
      text-decoration: none;
      transition: opacity .2s;
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        top: 3px;
        width: 20px;
        height: 17px;
        margin-right: 2px;
        background-image: url("../images/x_white.svg");
        background-size: 100%;
      }
      &:hover {
        opacity: .6;
      }
    }
    .btnLine {
      display: inline-block;
      border-radius: 35px;
      overflow: hidden;
      transition: opacity .2s;
      margin-left: 8px;
      img {
        height: 35px;
      }
      &:hover {
        opacity: .6;
      }
    }
    .btnTw,
    .btnLine {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
    .linkList {
      margin-top: 24px;
      @media screen and (min-width: 768px) {
        margin-top: 0;
      }
      a {
        color: #7D7D7D;
        font-size: 12px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }
      li + li {
        margin-top: 8px;
        @media screen and (min-width: 768px) {
          margin-top: 0;
          margin-left: 24px;
        }
      }
    }
    .copylight {
      margin-top: 24px;
      @media screen and (min-width: 768px) {
        margin-top: 0;
      }
      small {
        font-size: 8px;
        @media screen and (min-width: 768px) {
          font-size: 12px;
        }
      }
      img.vector {
        width: 66px;
        @media screen and (min-width: 768px) {
          width: 104px;
        }
      }
      img.pazle {
        width: 61px;
        @media screen and (min-width: 768px) {
          width: 85px;
        }
      }
      span {
        margin: 2px 4px 0 8px;
      }
      span, small {
        position: relative;
        top: 3px;
      }
    }
  }
}