@font-face {
  font-family: 'pazle';
  src:  url('/assets/fonts/pazle.eot?xcsdwz');
  src:  url('/assets/fonts/pazle.eot?xcsdwz#iefix') format('embedded-opentype'),
    url('/assets/fonts/pazle.ttf?xcsdwz') format('truetype'),
    url('/assets/fonts/pazle.woff?xcsdwz') format('woff'),
    url('/assets/fonts/pazle.svg?xcsdwz#pazle') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon_"], [class*=" icon_"],
[class^="styles_icon_"], [class*=" styles_icon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pazle' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_replay10:before {
  content: "\e912";
  color: #fff;
}
.icon_forward10:before {
  content: "\e911";
  color: #fff;
}
.icon_pause:before {
  content: "\e910";
  color: #fff;
}
.icon_mycontents_outline:before {
  content: "\e90e";
  color: #fff;
}
.icon_home_outline:before {
  content: "\e90f";
  color: #fff;
}
.icon_arrow_down_circle:before {
  content: "\e90d";
}
.icon_check:before {
  content: "\e90c";
  color: #fff;
}
.icon_user_outline:before {
  content: "\e90b";
  color: #fff;
}
.icon_check_circle:before {
  content: "\e90a";
  color: #fff;
}
.icon_arrow_forward:before {
  content: "\e909";
}
.icon_arrow_back:before {
  content: "\e908";
}
.icon_play_circle:before {
  content: "\e907";
}
.icon_close:before {
  content: "\e906";
}
.icon_home:before {
  content: "\e900";
}
.icon_mycontents:before {
  content: "\e901";
}
.icon_user:before {
  content: "\e902";
}
.icon_play:before {
  content: "\e903";
}
.icon_volume_on:before {
  content: "\e904";
}
.icon_volume_off:before {
  content: "\e905";
}