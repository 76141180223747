@import "./variables";

.contentsView {
  .announce_ended_video {
    display: grid;
    place-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 16px;
    backdrop-filter: blur(10px);
    background-color: $color_background_overlay_dark;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
    @media screen and (min-width: 600px) {
        border-radius: 4px;
    }
    .info_free {
        background: $color_contents_grad2;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.6;
        strong {
            font-size: 20px;
        }
    }
    .info_title {
        color: $color_contents_inverse;
        font-size: 16px;
        font-weight: bold;
    }
    .info_actors {
        display: flex;
        justify-content: center;
        margin-top: 8px;
    }
    .info_actorsItem {
        display: flex;
        align-items: center;
        + .info_actorsItem {
            margin-left: 16px;
        }
    }
    .info_actorImage {
        display: block;
        width: 44px;
    }
    .info_actorName,
    .info_actorRole {
        color: $color_contents_inverse;
        font-weight: 500;
        line-height: 1.6;
        text-align: left;
    }
    .info_actorName {
        margin-left: 8px;
        font-size: 12px;
    }
    .info_actorRole {
        margin-left: 8px;
        font-size: 10px;
        opacity: .6;
    }
    .info_outline {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        margin-bottom: 0;
        color: #fff;
        font-size: 12px;
        line-height: 1.6;
    }
    .info_prText {
        color: $color_contents_inverse;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.6;
        p + p {
            margin-top: 8px;
        }
    }
    .info_prActors {
        display: flex;
        justify-content: center;
        margin-top: 16px;
    }
    .info_prActorImage img {
        width: 80px;
    }
    .info_prActorImage + .info_prActorImage  {
        margin-top: 24px;
        margin-left: -16px;
    }
    .price {
        font-size: 24px;
        background: $color_contents_grad;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        strong {
            font-size: 32px;
        }
        small {
            font-size: 8px;
        }
    }
    .donationText + .btn_secondary {
        margin-top: 24px;
    }
    .info + .btns {
        margin-top: 8px;
    }
    .donationText {
        margin-top: 8px;
        font-size: 10px;
        font-weight: 500;
        background: $color_contents_grad;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .timeLeft {
        margin-bottom: 8px;
        color: #fff;
        font-size: 10px;
    }
}
.modal_contents.ended .announce_ended_video {
    visibility: visible;
    opacity: 1;
    .inner {
        width: 100%;
    }
  }

  .controller {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0,.6);
      visibility: hidden;
      opacity: 0;
      transition: opacity .2s, visibility .2s;
      &.active {
          visibility: visible;
          opacity: 1;
      }
  }
  .controller_btn {
      position: absolute;
      top: 45%;
      transform: translate(-50%);
      left: 50%;
      display: flex;
      button {
          border: none;
          cursor: pointer;
      }
      button + button {
          margin-left: 40px;
      }
      i {
          color: $color_contents_inverse;
      }
      .controller_btn_replay10 i,
      .controller_btn_forward10 i {
          font-size: 48px;
      }
      .controller_btn_playpause i {
          font-size: 56px;
      }
      
  }
  .progress_bar {
      position: absolute;
      z-index: 1;
      height: 4px;
      background-color: $color_background_overlay_light;
      @media screen and (max-width: 599px) {
          bottom: 16px;
          left: 16px;
          width: calc(100% - 32px);
          border-radius: 4px;
      }
      @media screen and (min-width: 600px) {
          bottom: 0;
          width: 100%;
          border-radius: 0 0 4px 4px;
      }
      .value {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 4px;
          background: $color_background_grad;
          @media screen and (max-width: 599px) {
              border-radius: 4px;
          }
          @media screen and (min-width: 600px) {
              border-radius: 0 0 4px 4px;
          }
      }
      .handle {
          display: grid;
          place-items: center;
          position: absolute;
          z-index: 201;
          bottom: -30px;
          left: 0;
          width: 64px;
          height: 64px;
          margin-left: -32px;
          &::after {
              content: "";
              display: inline-block;
              width: 16px;
              height: 16px;
              border-radius: 8px;
              background-color: $color_background_warning;
              cursor: pointer;
          }
      }
  }

  .btn_mute {
      display: grid;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border: none;
      border-radius: 16px;
      background-color: $color_background_overlay_dark;
      color: $color_contents_inverse;
      cursor: pointer;
      &::before {
          font-family: "pazle";
          content: "\e904";
          font-size: 18px;
          opacity: .4;
      }
  }
  .muted .btn_mute {
      background: linear-gradient(to right, #00AFFFb3 0%, #00AFFFb3 10%, #9D76FFb3 60%, #FF53FFb3 80%, #FF53FFb3 100%);
      &::before {
          opacity: 1;
      }
  }

  .muted .btn_mute::before {
      content: "\e905";
  }

  p + .btns {
      margin-top: 48px;
  }

  .btn_primary, 
  .btn_secondary {
      display: inline-block;
      position: relative;
      border: none;
      cursor: pointer;
      font-weight: 500;
  }

  .btn_primary {
    width: calc(100% - 32px);
    padding-left: 24px;
    padding-right: 24px;
    height: 48px;
    border-radius: 25px;
    background: $color_background_grad border-box border-box;
    color: $color_contents_inverse;
    &:disabled {
        background: $states_overlay_disabled_background;
        color: $states_overlay_disabled_oncolor;
        cursor: default;
    }
}

.btn_secondary {
    width: 250px;
    height: 40px;
    background: none;
    color: $color_contents_inverse;
    border-radius: 20px;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid transparent;
        border-radius: 20px;
        background: $color_background_grad border-box border-box;
        mask: linear-gradient($color_background_primary 0 0) padding-box, linear-gradient($color_background_primary 0 0) border-box;
        mask-composite: exclude;
    }
    &:disabled {
        background: $states_overlay_disabled_background;
        color: $states_overlay_disabled_oncolor !important;
        cursor: default;
    }
    &:disabled::before {
        background: $states_overlay_disabled_background;
    }
}

  .btns {
      button + button {
          margin-top: 32px;
      }
      .btn_buy {
          span {
              position: relative;
          }
          strong {
              position: relative;
              top: 2px;
              font-size: 20px;
          }
          small {
              font-size: 10px;
          }
      }
  }
  .modal_contents {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200;
    background-color: $color_background_overlay_dark;
    backdrop-filter: blur(10px);
    &::after {
        font-family: "pazle";
        content: "\e903";
        display: grid;
        place-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        visibility: hidden;
        width: 0;
        height: 0;
        border-radius: 100%;
        background-color: $color_background_overlay_dark;
        color: $color_contents_inverse;
        font-size: 0;
        transition: width .3s cubic-bezier(0.175, 0.885, 0.32, 1.275), height .3s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity .3s, visibility .3s, font-size .3s;
        pointer-events: none;
    }
    &.hidden {
        display: none;
    }
    &.ended .announce_ended_video {
        visibility: visible;
        opacity: 1;
        .inner {
            width: 100%;
        }
    }
}
.contents_header {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 64px;
        background: linear-gradient(to top, rgba(0, 2, 23, 0) 0%, rgba(0, 2, 23, 0.1) 20%, rgb(0, 2, 23, 0.6) 100%);
    }
    .btn_close {
        position: absolute;
        left: 8px;
        z-index: 100;
        display: grid;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border: none;
        border-radius: 16px;
        color: $color_contents_inverse;
        cursor: pointer;
        i {
            font-size: 24px;
        }
    }
}
.logo {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
    height: 56px;
}
.video_wrap {
    position: relative;
    width: fit-content;
    margin: auto;
    @media screen and (min-width: 600px) {
        top: 16px;
    }
}
video {
    position: relative;
    width: 100vw;
    height: 100dvh;
    object-fit: cover;
    @media screen and (min-width: 600px) {
        width: auto;
        height: calc(100dvh - 32px);
        overflow: hidden;
        border-radius: 4px;
    }
}
.contents_footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 24px 32px;
    &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        height: calc(100% + 48px);
        background: linear-gradient(to bottom, rgba(0,2,23,0) 0%,rgba(0,2,23,0.1) 20%, rgba(0,2,23,1) 100%);
        pointer-events: none;
        @media screen and (min-width: 600px) {
            border-radius: 4px;
        }
    }
    > * {
        position: relative;
        z-index: 2;
    }
}
.csp {
    position: absolute;
    right: 0;
    bottom: 56px;
    z-index: 10;
    width: 120px;
    padding: 30px 0 8px;
    border-radius: 10px;
    background: linear-gradient(to top, #FF5CA7 0%, #FFAA6A 80%, #FFFF57 200%);
    text-align: center;
    transition: opacity .2s, visibility .2s;
    &.hidden {
        opacity: 0;
        visibility: hidden;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        right: 28px;
        width: 12px;
        height: 12px;
        background-color: #FF5CA7;
        transform: rotate(45deg);
    }
    img {
        position: absolute;
        top: 4px;
        left: -6px;
        width: 110px;
    }
    .btnWrap {
        position: absolute;
        top: -16px;
        right: -16px;
        padding: 8px;
    }
    p {
        margin-top: 2px;
        color: $color_contents_inverse;
        font-size: 11px;
        line-height: 1.3;
    }
    button {
        display: grid;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 18px;
        height: 18px;
        border: 0.5px solid #fff;
        border-radius: 9px;
        background-color: rgba(255, 255, 255, .7);
        cursor: pointer;
        i {
            font-size: 8px;
        }
    }
}
.contents_info {
    width: calc(100% - 48px);
    margin-right: 16px;
    pointer-events: visible;
}
.contents_footer:has(.btn_purchase_wrap) .contents_info {
    width: calc(100% - 155px);
}
.info_actor {
    display: flex;
    li {
        display: flex;
        align-items: center;
    }
    li + li {
        margin-left: 8px;
    }
    .info_actor_img {
        width: 25px;
    }
    .info_actor_name {
        margin-left: 4px;
        color: $color_contents_inverse;
        font-size: 10px;
        line-height: 1.6;
    }
}
.info_actor + .info_title {
    margin-top: 8px;
    font-weight: 300;
}
.info_title {
    color: $color_contents_inverse;
    font-size: 14px;
}
.info_chapter {
    display: flex;
    align-items: center;
    height: 20px;
    line-height: 20px;
    margin-top: 4px;
    padding: 0 4px 0 8px;
    border: none;
    border-radius: 10px;
    background-color: $color_background_primary;
    font-size: 10px;
    i {
        margin-left: 2px;
    }
}
.info_highlight {
    height: 100%;
    max-height: 20px;
    margin-top: 8px;
    margin-bottom: 0;
    color: $color_contents_inverse;
    font-size: 11px;
    font-weight: 300;
    line-height: 1.6;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    transition: max-height .2s ease-in;
    &.active {
        max-height: 200px;
        white-space: pre-line;
    }
}
.contents_footer_btns {
    display: flex;
    align-items: center;
    .btn_secondary,
    .btn_primary {
        display: block;
        margin-left: auto;
        margin-right: 0;
        border: 0;
        position: relative;
        z-index: 2;
        width: auto;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
    }
}
.btn_purchase_wrap {
    position: relative;
    margin-left: 16px;
    .btn_purchase_supplement {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 48px;
        width: 100%;
        text-align: center;
        p {
            position: relative;
            display: inline-block;
            color: #fff;
            font-size: 10px;
            line-height: 1.4;
        }
        p::before,
        p::after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 28px;
            background-color: #fff;
        }
        p::before {
            top: 2px;
            left: -8px;
            transform: rotate(-20deg);
        }
        p::after {
            top: 2px;
            right: -4px;
            transform: rotate(20deg);
        }
    }
}
.announce_ended_video {
    display: grid;
    place-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 16px;
    backdrop-filter: blur(10px);
    background-color: $color_background_overlay_dark;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
    @media screen and (min-width: 600px) {
        overflow: hidden;
        border-radius: 4px;
    }
    .info_free {
        background: $color_contents_grad2;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.6;
        strong {
            font-size: 20px;
        }
    }
    .info_title {
        color: $color_contents_inverse;
        font-size: 16px;
        font-weight: bold;
    }
    .info_actors {
        display: flex;
        justify-content: center;
        margin-top: 8px;
    }
    .info_actorsItem {
        display: flex;
        align-items: center;
        + .info_actorsItem {
            margin-left: 16px;
        }
    }
    .info_actorImage {
        display: block;
        width: 44px;
    }
    .info_actorName,
    .info_actorRole {
        color: $color_contents_inverse;
        font-weight: 500;
        line-height: 1.6;
        text-align: left;
    }
    .info_actorName {
        margin-left: 8px;
        font-size: 12px;
    }
    .info_actorRole {
        margin-left: 8px;
        font-size: 10px;
        opacity: .6;
    }
    .info_outline {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        margin-bottom: 0;
        color: #fff;
        font-size: 12px;
        line-height: 1.6;
    }
    .info_prText {
        color: $color_contents_inverse;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.6;
        p + p {
            margin-top: 8px;
        }
    }
    .info_prActors {
        display: flex;
        justify-content: center;
        margin-top: 16px;
    }
    .info_prActorImage img {
        width: 80px;
    }
    .info_prActorImage + .info_prActorImage  {
        margin-top: 24px;
        margin-left: -16px;
    }
    .price {
        font-size: 24px;
        background: $color_contents_grad;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        strong {
            font-size: 32px;
        }
        small {
            font-size: 8px;
        }
    }
    .donationText + .btn_secondary {
        margin-top: 24px;
    }
    .info + .btns {
        margin-top: 8px;
    }
    .donationText {
        margin-top: 8px;
        font-size: 10px;
        font-weight: 500;
        background: $color_contents_grad;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .timeLeft {
        margin-bottom: 8px;
        color: #fff;
        font-size: 10px;
    }
  }

  .chapters {
    position: fixed;
    z-index: 300;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.7);
    backdrop-filter: blur(10px);
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s, visibility .2s;
    &.active {
        visibility: visible;
        opacity: 1;
    }
    .wrap {
        position: relative;
        width: 248px;
        height: 100%;
        background-color: $color_background_primary;
    }
    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .chaptersHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        p {
            width: 100%;
            padding-left: 16px;
            font-size: 14px;
            font-weight: 500;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        button {
            border: none;
            width: 48px;
            height: 48px;
        }
        i {
            color: $color_contents_secondary;
            font-size: 24px;
        }
    }
    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-top: 1px solid $color_border;
        cursor: pointer;
        &.active {
            background-color: rgba(83, 174, 248, .3);
        }
        .chapterTitle {
            font-size: 14px;
            font-weight: 500;
        }
        .chapterTime {
            color: $color_contents_secondary;
            font-size: 11px;
        }
        .chaptersPlay {
            display: flex;
            align-items: center;
            font-size: 11px;
            i {
                margin-left: 2px;
            }
        }
    }
  }
}