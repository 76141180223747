@import "./variables";

.MyContents {
    padding: 80px 32px 88px;
    margin: auto;
    max-width: 640px;
    h1 {
        display: inline-block;
        margin-bottom: 24px;
        font-size: 24px;
        background: $color_contents_grad2;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .libraries {
        @media screen and (min-width: 600px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: -8px;
        }
        > li {
            position: relative;
            margin: auto;
            @media screen and (min-width: 600px) {
                width: calc(100% / 2 - 16px);
                margin: 8px;
            }
        }
        > li .libraries_inner {
            position: relative;
            z-index: 1;
            padding: 24px 24px 16px;
            box-shadow: 0 5px 25px rgba(0, 0, 0, .2);
            border-radius: 10px;
            cursor: pointer;
            &::after {
                font-family: "pazle";
                content: "\e907";
                display: inline-block;
                position: absolute;
                right: 24px;
                bottom: 18px;
                color: $color_contents_inverse;
                font-size: 24px;
            }
        }
        > li + li {
            margin-top: 16px;
            @media screen and (min-width: 600px) {
                margin-top: 8px;
            }
        }
        .category {
            color: $color_contents_inverse;
            font-size: 12px;
        }
        .thumbnail {
            width: 100%;
        }
        h2 {
            width: calc(100% - 32px);
            margin-top: 8px;
            color: $color_contents_inverse;
            font-size: 16px;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .actor_name {
            display: flex;
            margin-top: 4px;
            color: $color_contents_inverse;
            font-size: 12px;
            li {
                + li::before {
                    content: "・";
                    display: inline-block;
                }
            }
            &::before {
                content: "CV：";
                display: inline-block;
            }
        }
    }
    .empty,
    .non_login {
        height: 100%;
        padding: 32px;
        color: #535353;
        text-align: center;
        h2 {
            margin: 16px auto 0
        }
        p {
            margin-top: 8px;
            color: #878787;
            font-size: 12px;
        }
        img {
            display: block;
            width: 100%;
            margin: auto;
        }
        button {
            width: 100%;
            height: 48px;
            margin: 32px auto 0;
            padding: 0 32px;
            border: none;
            border-radius: 24px;
            background: $color_background_grad;
            color: $color_contents_inverse;
            cursor: pointer;
            font-weight: 500;
            @media screen and (min-width: 600px) {
                width: fit-content;
                min-width: 240px;
            }
        }
        @media screen and (min-width: 600px) {
            br {
                display: none;
            }
        }
    }
    .empty img {
        max-width: 333px;
    }
    .non_login img {
        max-width: 137px;
    }
    .btn_wrap {
        text-align: center;
        button {
            position: relative;
            margin-top: 32px;
            background-color: $color_background_primary;
            &::before {
                content: "";
                position: absolute;
                top: 1px;
                left: 1px;
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                border-radius: 9999px;
            }
            span {
                position: relative;
                z-index: 1;
                color: $color_contents_primary;
            }
        }
    }
    .loadingPurchase {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 50;
        display: grid;
        align-items: center;
        justify-content: center;
        background-color: $color_background_overlay_dark_pale;
        .inner {
            width: fit-content;
            height: fit-content;
            padding: 24px 32px;
            border-radius: 24px;
            background-color: $color_background_primary;
        }
    }
    .modal {
        position: fixed;
        z-index: 500;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: grid;
        align-items: center;
        justify-content: center;
        .overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $color_background_overlay_dark_pale;
        }
        .modalInner {
            position: relative;
            z-index: 1;
            padding: 40px 32px;
            border-radius: 24px;
            background-color: $color_background_primary;
            text-align: center;
        }
        h2 {
            font-size: 24px;
            background: $color_contents_grad2;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        p {
            margin-top: 16px;
            margin-bottom: 24px;
        }
        .btn {
            margin-top: 32px;
        }
        .btns {
            display: flex;
            flex-direction: column;
            > button + button {
                margin-top: 16px;
                color: $color_contents_primary;
            }
        }
    }

    .loading_video {
        display: grid;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color_background_overlay_dark;
        color: $color_contents_inverse;
      }

      .snowman {
        position: absolute;
        z-index: 0;
        transition: bottom 0.8s ease-in, z-index 0.2s linear;
        &.snowman_s {
            top: -37px;
            right: 22px;
            transform: rotate(-90deg);
            img {
                width: 130px !important;
            }
            &.snowman_yoko {
                animation: .8s linear .2s 1 forwards yokoS;
            }
            &.snowman_tate {
                animation: .8s linear .2s 1 forwards tateS;
            }
        }
        &.snowman_l {
            top: -56px;
            right: -40px;
            transform: rotate(90deg);
            img {
                width: 160px !important;
            }
            &.snowman_yoko {
                animation: .8s linear .1s 1 forwards yokoL;
            }
            &.snowman_tate {
                animation: .8s linear .1s 1 forwards tateL;
            }
        }
    }
    @keyframes yokoL {
        0% {
            transform: rotate(90deg);
        }
        80% {
           transform: rotate(270deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes yokoS {
        0% {
            transform: rotate(-90deg);
        }
        80% {
           transform: rotate(-270deg);
        }
        100% {
            transform: rotate(-360deg);
        }
    }
    @keyframes tateL {
        0% {
            transform: rotate(90deg);
        }
        80% {
           transform: rotate(270deg);
        }
        100% {
            transform: rotate(450deg);
        }
    }
    @keyframes tateS {
        0% {
            transform: rotate(-90deg);
        }
        80% {
           transform: rotate(-270deg);
        }
        100% {
            transform: rotate(-450deg);
        }
    }
}