@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;500;700;800&family=Simonetta:ital,wght@1,900&display=swap');
@import "~sanitize.css";
@import "~sanitize.css/forms.css";
@import "~sanitize.css/typography.css";
@import "./variables";

body {
  background-color: $color_background_secondary;
  // background-color: #DFE9F1; // note: 冬に使用
  color: $color_contents_primary;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .05em;
}

h1, h2, h3 {
  margin: 0;
}

button {
  padding: 0;
}

p, ul {
  margin-block-start: 0;
  margin-block-end: 0;
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
}

a {
  color: $color_contents_accent;
  font-weight: 500;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

p {
  line-height: 2;
  &.caption {
    color: $color_contents_secondary;
    font-size: 12px;
    line-height: 1.8;
  }
}

.noticeDev ~ header {
  top: 32px;
}